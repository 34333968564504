import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader'; // Import a spinner
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import ProductCard from '../components/ProductCard';
import '../styles/Category.css';

function Search() {
  const { searchString } = useParams();

  const [products, setProducts] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    setLoadingProducts(true);
    const fetchProducts = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/search`, { searchString }, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setProducts(response.data.products);
        setLoadingProducts(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoadingProducts(false); // Ensure loading state is updated on error
      }
    };
    fetchProducts();
  }, [searchString]);

  // Function to split products into chunks of 4
  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <>
      <Navbar />
      <div className="global-container">
        {!loadingProducts ? (
          <>
            <h1>Search result</h1>
            <br />
            {chunkArray(products, 4).map((productRow, rowIndex) => (
              <div className='row' key={rowIndex}>
                {productRow.map((product, index) => (
                  <div className='category-product-column' key={index}>
                    <ProductCard 
                      id={product._id} 
                      name={product.pricePerKgInCents === 0 ? product.name : product.name + ' | ' + 'Sliced'} 
                      img={`${process.env.REACT_APP_API_URL}/${product.images[0]}`} 
                      weightInGrams={product.weightInGrams} 
                      pricePerKgInCents={product.pricePerKgInCents} 
                      availableSizes={product.availableSizes}
                      available={product.available}
                      description={`£${product.pricePerKgInCents === 0 ? product.priceInCents / 100 : product.pricePerKgInCents / 1000 + ' / 100g '}`} 
                      slug={product.slug}
                    />
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : (
          <div className="loading-container">
            <ClipLoader size={50} color="#007bff" loading={true} />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Search;
