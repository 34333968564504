import React, { useState } from 'react';
import axios from 'axios';

import FeedbackPopup from '../../components/widgets/FeedbackPopUp';

import '../../styles/components/widgets/NewsletterBox.css';

const NewsletterBox = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!name || !email) {
      setErrorMessage("Please fill in all fields.");
      return;
    }
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
  
    setErrorMessage("");
  
    const data = {
      email,
      name,  // Send the name and email as part of the request body
    };
  
    try {
      // Make a POST request to your backend API endpoint
      await axios.post(
        `${process.env.REACT_APP_API_URL}/newsletter/subscribe`, data, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        }
      );
      setFeedbackMessage("You have successfully subscribed to our newsletter!");
    } catch (error) {
      console.error('Error subscribing to newsletter:', error.response?.data || error);
      setFeedbackMessage("Failed to subscribe. Please try again.");
    } finally {
      setIsPopupOpen(true);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  return (
    <div className='newsletter-box'>
      <h3>Free schiacciata with nutella? Join our newsletter now</h3>
      <br />
      <img src={`${process.env.REACT_APP_API_URL}/images/nutella.png`} />
      <br />
      <input 
        type='text' 
        name='name' 
        placeholder='Name' 
        value={name} 
        onChange={(e) => setName(e.target.value)} 
      />
      <input 
        type='email' 
        name='email' 
        placeholder='Email' 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
      />
      {/* Display validation error */}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <br />
      <button className='button' onClick={() => handleSubmit()}>Subscribe</button>
      {/* Render the FeedbackPopup */}
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
        showCancel={false} // Only show "Close" button
      />
    </div>
  );
};

export default NewsletterBox;
