import React from 'react';

import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';

function TermsOfService() {
  return (
    <div>
      <Navbar />
      <div className='global-container'>
        <h2>Terms of Service</h2>
        <br />
        <p>Effective Date: 23/06/2024</p>

        <br />

        <p>Welcome to Dal Fiorentino! These Terms of Service ("Terms") govern your use of our website www.dalfiorentino.london and the services we offer. By accessing or using our website, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website.</p>

        <br />

        <h3>1. Use of Our Website</h3>
        <br />
        <p>You agree to use our website only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the website. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our website.</p>
        
        <br />

        <h3>2. Account Registration</h3>
        <br />
        <p>To access certain features of our website, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and for all activities that occur under your account. You agree to notify us immediately of any unauthorised use of your account.</p>

        <br />

        <h3>3. Orders and Payments</h3>
        <br />
        <p>All orders placed through our website are subject to acceptance and availability. We reserve the right to refuse or cancel any order for any reason, including errors in pricing or product information. Payments are processed securely, and you agree to provide accurate payment information. All prices are listed in Pound sterling (£) and include applicable taxes unless otherwise stated.</p>

        <br />

        <h3>4. Pick-Up Only</h3> 
        <br /> 
        <p>We offer pick-up services only; no delivery options are available. All orders must be collected within the store's opening hours on the specified pick-up date. Please ensure to collect your order during these hours, as we cannot hold items beyond the pick-up period. Details regarding the pick-up location and store hours will be provided at checkout.</p>

        <br />

        <h3>5. Returns and Refunds</h3>
        <br />
        <p>If you are not satisfied with your purchase, please contact us at hello@dalfiorentino.london within 1 days of receiving your order. We will review your request and may offer a refund or replacement at our discretion. Please note that certain items may not be eligible for return or refund.</p>

        <br />

        <h3>6. Intellectual Property Rights</h3>
        <br />
        <p>All content on our website, including text, graphics, logos, images, and software, is the property of Dal Fiorentino or its content suppliers and is protected by intellectual property laws. You may not reproduce, distribute, modify, or create derivative works of any content on our website without our prior written consent.</p>

        <br />

        <h3>7. Limitation of Liability</h3>
        <br />
        <p>To the fullest extent permitted by law, Dal Fiorentino shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use our website; (b) any unauthorised access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from our website; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our website by any third party; or (e) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through our website.</p>

        <br />

        <h3>8. Indemnification</h3>
        <br />
        <p>You agree to indemnify, defend, and hold harmless Dal Fiorentino, its officers, directors, employees, agents, and affiliates, from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of our website or your violation of these Terms.</p>

        <br />

        <h3>9. Changes to These Terms</h3>
        <br />
        <p>We may update these Terms from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our website after any such changes constitutes your acceptance of the new Terms.</p>

        <br />

        <h3>10. Governing Law</h3>
        <br />
        <p>These Terms shall be governed and construed in accordance with the laws of United Kingdom, without regard to its conflict of law provisions. You agree to submit to the personal jurisdiction of the courts located in United Kingdom for the purpose of litigating any disputes.</p>

        <br />

        <h3>11. Contact Us</h3>
        <br />
        <p>If you have any questions about these Terms, please contact us at:</p>
        <br />
        <p>Dal Fiorentino<br />
        <br />
        74 Hoxton Street, London, England, N1 6LP<br />
        Email: hello@dalfiorentino.london<br />
        Phone: +44 07783 437620</p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsOfService;
