import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import NotFound from '../../NotFound';
import '../../../styles/admin/Forms.css';

function Refund() {
  const { user } = useAuth();

  const navigate = useNavigate();

  const { orderId } = useParams();

  const [userData, setUserData] = useState();
  const [order, setOrder] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/order/${orderId}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setOrder(response.data.order);
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };

    fetchOrderData();
  }, [orderId]);

  const handleCheckboxChange = (item) => {
    setSelectedItems((prev) => {
      if (prev.includes(item)) {
        return prev.filter((i) => i !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  useEffect(() => {
    console.log(selectedItems)
  }, [selectedItems]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedItems.length) {
      setFeedbackMessage("Please select at least one item to refund.");
      setIsPopupOpen(true);
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/refund/partial`,
        {
          order,
          basketItems: selectedItems,
        },
        {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );
      setFeedbackMessage("Order refunded successfully!");
    } catch (error) {
      console.error('Error submitting form:', error);
      setFeedbackMessage("Failed to refund order. Please try again.");
    } finally {
      setIsPopupOpen(true);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage("");
    navigate(`/`)
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />;
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <div className='form-container'>
          <h1>Refund Order</h1>
          <br />
          <p>Select the items you'd like to refund</p>
          <br /><br />
          <form onSubmit={handleSubmit}>
            <table className='table'>
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Original Price</th>
                  <th>Paid</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {order &&
                  order.basketItems.map((item) => (
                    <tr key={item.product._id}>
                      <td>
                        {item.status !== 'refunded' ? (
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(item)}
                            checked={selectedItems.includes(item)}
                          />
                        ) : null}
                      </td>
                      <td>
                        <img
                          src={`${process.env.REACT_APP_API_URL}/${item.product.images[0]}`}
                          alt={item.product.name}
                        />
                      </td>
                      <td>{item.product.name}</td>
                      <td>{item.quantity}</td>
                      <td>
                        {'£' + ((!item.product.pricePerKgInCents ? item.product.priceInCents / 100 : item.product.pricePerKgInCents / 100000 * item.grams) * item.quantity).toFixed(2)}
                      </td>
                      <td>
                        {'£' + (item.paidInCents / 100).toFixed(2)}
                      </td>
                      <td>{item.status}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <br /><br />
            <button type='submit' className='button'>
              Refund
            </button>
          </form>
        </div>
      </div>
      <Footer />
      <FeedbackPopup isOpen={isPopupOpen} message={feedbackMessage} onClose={closePopup} />
    </>
  );
}

export default Refund;
