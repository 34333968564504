import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../components/AuthContext';
import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';
import NotFound from '../NotFound';
import '../../styles/admin/Panel.css';

function Panel() {
  const { user } = useAuth();

  const [userData, setUserData] = useState();

  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    setLoadingProducts(true);
    const fetchProducts = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/products', {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setProducts(response.data.products);
        setLoadingProducts(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoadingProducts(false);
      }
    };

    fetchProducts();
  }, []);

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='row'>
        <div className='admin-panel-left-column'>
          <br /><br />
          <h2>Orders</h2>
          <br />
          <p><Link to='/admin/orders'>Dashboard</Link></p>
          <br />
          <p><Link to='/admin/orders/all'>All orders</Link></p>
          <br /><br />
          <h2>Messages</h2>
          <br />
          <p><Link to='/admin/contact-requests'>View</Link></p>
          <br /><br />
          <h2>Products</h2>
          <br />
          <p><Link to='/admin/products'>View</Link></p>
          <br />
          <p><Link to='/admin/product/new'>New</Link></p>
          <br /><br />
          <h2>Categories</h2>
          <br />
          <p><Link to='/admin/categories'>View</Link></p>
          <br />
          <p><Link to='/admin/category/new'>New</Link></p>
          <br /><br />
          <h2>Menu</h2>
          <br />
          <p><Link to='/admin/menu-items'>View</Link></p>
          <br />
          <p><Link to='/admin/menu-item/new'>New</Link></p>
          <br /><br />
          <h2>Discount Codes</h2>
          <br />
          <p><Link to='/admin/discount-codes'>View</Link></p>
          <br />
          <p><Link to='/admin/discount-code/new'>New</Link></p>
        </div>
        <div className='admin-panel-right-column'>
          <h2 style={{ textAlign: 'center' }}>Products</h2>
          <br />
          {!loadingProducts ? (
            <table className='table'>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Categories</th>
                  <th>Weight</th>
                  <th>Price</th>
                  <th>Price Per Kg</th>
                </tr>
              </thead>
              <tbody>
                {products.map(product => (
                  <tr key={product._id}>
                    <td><Link to={`/product/${product.slug}`}><img src={`${process.env.REACT_APP_API_URL}/${product.images[0]}`} /></Link></td>
                    <td>{product.name}</td>
                    <td>
                      {product.categories.map((category, index) => (
                        <>
                          <span key={index}>
                            {category.name}
                            {index < product.categories.length - 1}
                          </span>
                          <br />
                        </>
                      ))}
                    </td>
                    <td>{product.weightInGrams != 0 ? product.weightInGrams + 'g' : 'N/A'}</td>
                    <td>{product.priceInCents != 0 ? '£' + (product.priceInCents / 100) : 'N/A'}</td>
                    <td>{product.pricePerKgInCents != 0 ? '£' + (product.pricePerKgInCents / 100) : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>Loading products...</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Panel;