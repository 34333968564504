import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import NotFound from '../../NotFound';
import '../../../styles/admin/Forms.css';

function View() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [menuItems, setMenuItems] = useState([]);
  const [loadingMenuItems, setLoadingMenuItems] = useState(true);
  const [deleteMenuItemId, setDeleteMenuItemId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    setLoadingMenuItems(true);
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/menu-items', {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setMenuItems(response.data.menuItems);
        setLoadingMenuItems(false);
      } catch (error) {
        console.error('Error fetching menuItems:', error);
        setLoadingMenuItems(false);
      }
    };

    fetchMenuItems();
  }, []);

  const handleDeleteConfirmation = (menuItemId) => {
    setDeleteMenuItemId(menuItemId); // Set the menuItem ID to delete
    setIsPopupOpen(true); // Open the confirmation popup
  };

  const confirmDelete = async () => {
    if (deleteMenuItemId) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/menu-item/${deleteMenuItemId}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });

        // Update menuItem state correctly
        setMenuItems(currentMenuItems =>
          currentMenuItems.filter(menuItem => menuItem._id !== deleteMenuItemId)
        );

        setFeedbackMessage("MenuItem deleted successfully!"); // Set success message
      } catch (error) {
        console.error('Error deleting menuItem:', error);
        setFeedbackMessage("Failed to delete menuItem. Please try again."); // Set error message
      } finally {
        setIsPopupOpen(false); // Close the popup
      }
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <h1 style={{ textAlign: 'center' }}>Menu</h1>
        <br />
        <p style={{ textAlign: 'center' }}>View, Edit and Delete Menu Items</p>
        <br /><br />
        <table className='table'>
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Category</th>
              <th>Price (Small)</th>
              <th>Price (Large)</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {menuItems.map(menuItem => (
              <tr key={menuItem._id}>
                <td><Link to={`/menu-item/${menuItem.slug}`}><img src={`${process.env.REACT_APP_API_URL}/${menuItem.images[0]}`} alt={menuItem.name} /></Link></td>
                <td>{menuItem.name}</td>
                <td>{menuItem.category}</td>
                <td>£{menuItem.pricesInCents.small / 100}</td>
                <td>£{menuItem.pricesInCents.large / 100}</td>
                <td><Link to={`/admin/menu-item/edit/${menuItem._id}`}><button className='button'>Edit</button></Link></td>
                <td><button className='button' onClick={() => handleDeleteConfirmation(menuItem._id)}>Delete</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Footer />

      <FeedbackPopup
        isOpen={isPopupOpen}
        message={"Are you sure you want to delete this menuItem?"}
        onClose={closePopup}
        onConfirm={confirmDelete}
        confirmButtonText="Confirm Deletion"
        isAdmin={userData && userData.type === 'admin'} // Pass isAdmin prop based on user role
      />
    </>
  );
}

export default View;
