import React from 'react';

import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';

function PrivacyPolicy() {
  return (
    <div>
      <Navbar />
      <div className='global-container'>
        <h2>Privacy Policy</h2>

        <br />

        <p>Effective Date: 23/06/2024</p>

        <br />

        <p>Welcome to Dal Fiorentino! This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.dalfiorentino.london or interact with our services. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it. By using our website, you agree to the terms of this Privacy Policy.</p>

        <br /><br />

        <h3>1. Information We Collect</h3>
        <br />
        <p>We collect information from and about users of our website, including:</p>
        <br />
        <ul>
          <li><strong>Personal Identification Information:</strong> Name, email address, phone number, and address.</li>
          <li><strong>Order Information:</strong> Products purchased, date and time of purchase, payment information.</li>
          <li><strong>Technical Data:</strong> IP address, browser type, operating system, and pages visited.</li>
        </ul>

        <br /><br />

        <h3>2. How We Use Your Information</h3>
        <br />
        <p>We use the information we collect in the following ways:</p>
        <br />
        <ul>
          <li><strong>To Provide and Manage Services:</strong> Process your orders, manage your account, and provide customer support.</li>
          <li><strong>To Improve Our Services:</strong> Analyze usage to enhance user experience, troubleshoot issues, and improve website functionality.</li>
          <li><strong>To Communicate with You:</strong> Send you updates about your orders, respond to inquiries, and provide information about new products or promotions.</li>
        </ul>

        <br /><br />

        <h3>3. How We Share Your Information</h3>
        <br />
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except as described below:</p>
        <br />
        <ul>
          <li><strong>Service Providers:</strong> We may share information with third parties that perform services on our behalf, such as payment processing and delivery services.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your information to comply with legal obligations, enforce our site policies, or protect our rights.</li>
        </ul>

        <br /><br />

        <h3>4. Data Security</h3>
        <br />
        <p>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no internet-based site can be 100% secure, and we cannot guarantee absolute security of your data.</p>
        
        <br /><br />

        <h3>5. Cookies and Tracking Technologies</h3>
        <br />
        <p>Our website uses cookies and similar tracking technologies to enhance your browsing experience and analyze site traffic. You can set your browser to refuse cookies, but some features of our site may not function properly without them.</p>

        <br /><br />

        <h3>6. Third-Party Links</h3>
        <br />
        <p>Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of these sites. Please review the privacy policies of any third-party sites you visit.</p>

        <br /><br />

        <h3>7. Your Data Protection Rights</h3>
        <br />
        <p>Depending on your location, you may have the following rights regarding your personal data:</p>
        <br />
        <ul>
          <li><strong>Access:</strong> Request a copy of the information we hold about you.</li>
          <li><strong>Correction:</strong> Request that we correct any inaccuracies in your information.</li>
          <li><strong>Deletion:</strong> Request that we delete your personal data, under certain conditions.</li>
          <li><strong>Restriction:</strong> Request that we restrict the processing of your personal data, under certain conditions.</li>
          <li><strong>Objection:</strong> Object to our processing of your personal data, under certain conditions.</li>
          <li><strong>Portability:</strong> Request that we transfer your data to another organization, or directly to you, under certain conditions.</li>
        </ul>
        <br />
        <p>To exercise any of these rights, please contact us at: hello@dalfiorentino.london</p>

        <br /><br />

        <h3>8. Changes to This Privacy Policy</h3>
        <br />
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.</p>

        <br /><br />

        <h3>9. Contact Us</h3>
        <br />
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <br />
        <p>Dal Fiorentino<br />
        <br />
        74 Hoxton Street, London, England, N1 6LP<br />
        Email: hello@dalfiorentino.london<br />
        Phone: +44 07783 437620</p>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;