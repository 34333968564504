import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getPerformance } from 'firebase/performance';
import { getAnalytics } from 'firebase/analytics';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC4YMVN2lGI3QS5XGuBmacbwjMDFUnOBpc",
  authDomain: "dalfiorentino.firebaseapp.com",
  projectId: "dalfiorentino",
  storageBucket: "dalfiorentino.appspot.com",
  messagingSenderId: "147583186909",
  appId: "1:147583186909:web:c686879dc3d09c12dfe834",
  measurementId: "G-XE5Q76G02E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const GoogleProvider = new GoogleAuthProvider();

export const db = getFirestore(app);

export const storage = getStorage(app);

export const perf = getPerformance(app);
export const analytics = getAnalytics(app);