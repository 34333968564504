import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './styles/Global.css';

import { AuthProvider } from './components/AuthContext';
import ScrollToTop from './components/ScrollToTop';

import Home from './views/Home';
import Shop from './views/Shop';
import About from './views/About';
import Menu from './views/Menu';
import Contact from './views/Contact';
import Catering from './views/Catering';

import Signup from './views/Signup';
import Login from './views/Login';
import Account from './views/Account';

import Product from './views/Product';
import Category from './views/Category';

import Search from './views/Search';

import MenuItem from './views/MenuItem';

import Basket from './views/Basket';

import PaymentSuccess from './views/PaymentSuccess';

import AdminPanel from './views/admin/Panel';
import ProductsView from './views/admin/product/View';
import ProductNew from './views/admin/product/New';
import ProductEdit from './views/admin/product/Edit';
import MenuItemsView from './views/admin/menuItem/View';
import MenuItemNew from './views/admin/menuItem/New';
import MenuItemEdit from './views/admin/menuItem/Edit';
import CategoriesView from './views/admin/category/View';
import CategoryNew from './views/admin/category/New';
import CategoryEdit from './views/admin/category/Edit';
import OrdersView from './views/admin/order/View';
import OrdersAll from './views/admin/order/All';
import OrderEdit from './views/admin/order/Edit';
import OrderRefund from './views/admin/order/Refund';
import DiscountCodesView from './views/admin/discountCode/View';
import DiscountCodeNew from './views/admin/discountCode/New';
import ContactRequestsView from './views/admin/contactRequest/View';

import PrivacyPolicy from './views/legal/PrivacyPolicy';
import TermsOfService from './views/legal/TermsOfService';

import NotFound from './views/NotFound';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop/" element={<Shop />} />
          <Route path="/shop/:categorySlug" element={<Shop />} />
          <Route path="/menu/" element={<Menu />} />
          <Route path="/menu/:category" element={<Menu />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/catering" element={<Catering />} />

          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/account" element={<Account />} />

          <Route path="/product/:productSlug" element={<Product />} />
          <Route path="/product/category/:categorySlug" element={<Category />} />

          <Route path="/search/:searchString" element={<Search />} />

          <Route path="/menu/item/:menuItemSlug" element={<MenuItem />} />

          <Route path="/basket" element={<Basket />} />

          <Route path="/payment/success/:orderId" element={<PaymentSuccess />} />

          <Route path="/admin" element={<AdminPanel />} />
          <Route path="/admin/products" element={<ProductsView />} />
          <Route path="/admin/product/new" element={<ProductNew />} />
          <Route path="/admin/product/edit/:productId" element={<ProductEdit />} />
          <Route path="/admin/menu-items" element={<MenuItemsView />} />
          <Route path="/admin/menu-item/new" element={<MenuItemNew />} />
          <Route path="/admin/menu-item/edit/:menuItemId" element={<MenuItemEdit />} />
          <Route path="/admin/categories" element={<CategoriesView />} />
          <Route path="/admin/category/new" element={<CategoryNew />} />
          <Route path="/admin/category/edit/:categoryId" element={<CategoryEdit />} />
          <Route path="/admin/orders/" element={<OrdersView />} />
          <Route path="/admin/orders/all" element={<OrdersAll />} />
          <Route path="/admin/order/edit/:orderId" element={<OrderEdit />} />
          <Route path="/admin/order/refund/:orderId" element={<OrderRefund />} />
          <Route path="/admin/discount-codes" element={<DiscountCodesView />} />
          <Route path="/admin/discount-code/new" element={<DiscountCodeNew />} />
          <Route path="/admin/contact-requests" element={<ContactRequestsView />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />

          <Route path='*' element={<NotFound />}/>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
