import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import ClipLoader from 'react-spinners/ClipLoader'; // Import a spinner
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../styles/Product.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required Swiper modules
import { FreeMode, Pagination } from 'swiper/modules';

function MenuItem() {
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const { menuItemSlug } = useParams();

  const [menuItem, setMenuItem] = useState(null);
  const [loadingMenuItem, setLoadingMenuItem] = useState(true);

  useEffect(() => {
    setLoadingMenuItem(true);
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/menu-item/slug/${menuItemSlug}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setMenuItem(response.data.menuItem);
        setLoadingMenuItem(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setLoadingMenuItem(false); // Ensure loading state is updated on error
      }
    };

    fetchProduct();
  }, [menuItemSlug]);

  return (
    <>
      <Navbar />
      <div className="global-container">
        {!loadingMenuItem && menuItem ? (
          <div className='row'>
            <div className='product-left-column'>
            <Swiper
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  // when window width is >= 1024px
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination]}
              >
                {menuItem.images.map((image) => (
                  <SwiperSlide className='inside-product-swiper-slide'>
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${image}`}
                      alt="Image"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className='product-right-column'>
              <p style={{ color: 'green' }}>{menuItem.category.toUpperCase()}</p>
              <br />
              <h2>{menuItem.name}</h2>
              <br />
              <h3>£{menuItem.pricesInCents.small / 100} (Small) | £{menuItem.pricesInCents.large / 100} (Large)</h3>
              <br /><br />
              <hr />
              <br /><br />
              <p>{menuItem.name}</p>
              <br />
              <p>{menuItem.description}</p>
            </div>
          </div>
        ) : (
          <div className="loading-container">
            <ClipLoader size={50} color="#007bff" loading={true} />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default MenuItem;