import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import FeedbackPopup from '../components/widgets/FeedbackPopUp';
import '../styles/Contact.css';

function Catering() {
  const { user } = useAuth();

  const [userData, setUserData] = useState();
  const [loadingUserData, setLoadingUserData] = useState(true);

  const [stores, setStores] = useState([]);

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoadingUserData(true);
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
        setLoadingUserData(false);
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoadingUserData(false);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/stores`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setStores(response.data.stores);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchStores();
  }, []);

  const handleSendMessage = async (e) => {
    if(name === '' || email === '' || phone === '' || message === '') {
      setFeedbackMessage('All fields are required! Please fill them up.');
      setIsPopupOpen(true);
      return
    }
    
    e.preventDefault();
    const formData = new FormData();
    formData.append('user', user);
    formData.append('type', 'New Catering Request');
    formData.append('name', name);
    formData.append('subject', 'Catering Request');
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('message', message);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contact-request/new`, formData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json'
        },
      });
      setFeedbackMessage("Message sent successfully!");
    } catch (error) {
      console.error('Error submitting form:', error);
      setFeedbackMessage("Failed to send message. Please try again."); // Set error message
    } finally {
      setIsPopupOpen(true); // Open the popup regardless of success or failure
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  return (
    <div style={{ backgroundColor: '#ececec' }}>
      <Helmet>
        <title>Catering - Dal Fiorentino</title>
        <meta name="description" content="Are you planning your next event, birthday, or office party? We're here to make it special and can't wait to chat with you!" />        
        <meta name="twitter:card" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta name="twitter:site" content="@dalfiorentino.london" />        
        <meta name="twitter:creator" content="@dalfiorentino.london" />        
        <meta name="twitter:title" content="Catering - Dal Fiorentino" />        
        <meta name="twitter:description" content="Are you planning your next event, birthday, or office party? We're here to make it special and can't wait to chat with you!" />        
        <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta property="og:title" content="Catering - Dal Fiorentino" />        
        <meta property="og:description" content="Are you planning your next event, birthday, or office party? We're here to make it special and can't wait to chat with you!" />        
        <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
        <meta property="og:site_name" content="Catering - Dal Fiorentino" />
        <meta property="og:locale" content="en_UK" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="Dal Fiorentino, Italian flatbreads, schiacciate, Tuscan bread, traditional Italian shop, authentic Italian food, schiacciata London, Italian bakery, Italian cuisine London, fresh flatbreads, Tuscany specialties" />
      </Helmet>
      <Navbar />
      <div className='menu-container'>
        <div className='contact-container'>
          <br /><br />
          <h2 className='menu-title'>CATERING</h2>
          <p className='menu-welcome'>
            Catering & Large Orders
          </p>
          <br /><br />
          
          <p className='contact-description'>
            Are you planning your next <b>event, birthday, or office party?</b> We're here to make it special and can't wait to chat with you! If you've got questions about our menu, need help <b>organising a big order</b>, or want to learn more about our catering services, don’t hesitate to reach out. You can call us, drop us an email, or fill out the contact form on our website. Our dedicated team is ready to assist and will get back to you super pronto!
          </p>
          <p className='contact-description'>
            When you order our delicious schiacciate for your event, they’ll be <b>carefully arranged in pizza cardboard boxes</b>, each <b>cut into four pieces for easy sharing</b>. To make it even better, the <b>names of the schiacciate and their ingredients will be hand-written on each box</b>. Check out the video below to see for yourself!
          </p>
          <p className='contact-description'>
            We look forward to hearing from you and hope to see you soon at our shop, where you can savor the <b>authentic flavors of Florence in the heart of London</b>.
          </p>
          <br />
          <p className='contact-ending'><em>Arrivederci!</em></p>
          <br /><br />
          <div className='contact-info'>
            <div className='contact-info-item'>
              <i className='fas fa-map-marker-alt'></i>
              <p>Hoxton | 74 Hoxton St, London N1 6LP</p>
            </div>
            <div className='contact-info-item'>
              <i className='fas fa-map-marker-alt'></i>
              <p>Fitzrovia | 15 Great Titchfield St., London W1W 8AZ</p>
            </div>
            <div className='contact-info-item'>
              <i className='fas fa-map-marker-alt'></i>
              <p>Brick Lane | 6 Cheshire Street, London E2 6EH</p>
            </div>
            <div className='contact-info-item'>
              <i className='fas fa-phone'></i>
              <p>+44 07783 437620</p>
            </div>
            <div className='contact-info-item'>
              <i className='fas fa-envelope'></i>
              <p>hello@dalfiorentino.london</p>
            </div>
          </div>
          <br /><br />
          <div className='contact-form'>
            <div className='contact-form-group'>
              <label htmlFor='name'>Name</label>
              <input type='text' id='name' name='name' value={name} onChange={(e) => {setName(e.target.value)}} required />
            </div>
            <div className='contact-form-group'>
              <label htmlFor='email'>Email</label>
              <input type='email' id='email' name='email' value={email} onChange={(e) => {setEmail(e.target.value)}} required />
            </div>
            <div className='contact-form-group'>
              <label htmlFor='phone'>Phone Number</label>
              <input type='text' id='phone' name='phone' value={phone} onChange={(e) => {setPhone(e.target.value)}} required />
            </div>
            <div className='contact-form-group'>
              <label htmlFor='message'>Message</label>
              <textarea id='message' name='message' value={message} rows='5' onChange={(e) => {setMessage(e.target.value)}} required />
            </div>
            <button className='contact-button' onClick={handleSendMessage}>Send</button>
          </div>
          <br /><br />
          <div className="about-video">
            <iframe 
              src="https://www.instagram.com/reel/DCXAFGZo_ci/embed" 
              width="400" 
              height="540" 
              frameBorder="0" 
              scrolling="no" 
              allowTransparency="true" 
              allow="encrypted-media">
            </iframe>
          </div>
          <br /><br />
          <div className='contact-map-container'>
            {stores.map((store) => {
              return (
                <div className='contact-map' style={{ textAlign: 'center' }}>
                  <p><b>📍{store.name}</b></p>
                  <p style={{ fontSize: '16px' }}>{store.openingTimes}</p>
                  <br />
                  <iframe
                    src={store.embed_a_map}
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Our Location"
                  ></iframe>
                  <br /><br />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Footer />
      {/* Render the FeedbackPopup */}
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
        showCancel={false} // Only show "Close" button
      />
    </div>
  );
}

export default Catering;