import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import FeedbackPopup from '../components/widgets/FeedbackPopUp';
import '../styles/Contact.css';

function Contact() {
  const { user } = useAuth();

  const [userData, setUserData] = useState();
  const [loadingUserData, setLoadingUserData] = useState(true);

  const [stores, setStores] = useState([]);

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoadingUserData(true);
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
        setLoadingUserData(false);
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoadingUserData(false);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/stores`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setStores(response.data.stores);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchStores();
  }, []);

  const handleSendMessage = async (e) => {
    if(name === '' || subject === '' || email === '' || message === '') {
      setFeedbackMessage('All fields are required! Please fill them up.');
      setIsPopupOpen(true);
      return
    }
    
    e.preventDefault();
    const formData = new FormData();
    formData.append('user', user);
    formData.append('type', 'New Contact Request');
    formData.append('name', name);
    formData.append('subject', subject);
    formData.append('email', email);
    formData.append('message', message);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/contact-request/new`, formData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json'
        },
      });
      setFeedbackMessage("Message sent successfully!");
    } catch (error) {
      console.error('Error submitting form:', error);
      setFeedbackMessage("Failed to send message. Please try again."); // Set error message
    } finally {
      setIsPopupOpen(true); // Open the popup regardless of success or failure
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  return (
    <div style={{ backgroundColor: '#ececec' }}>
      <Helmet>
        <title>Contact - Dal Fiorentino</title>
        <meta name="description" content="Ask us about our menu, concerns, or enquiries about larger orders!" />        
        <meta name="twitter:card" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta name="twitter:site" content="@dalfiorentino.london" />        
        <meta name="twitter:creator" content="@dalfiorentino.london" />        
        <meta name="twitter:title" content="Contact - Dal Fiorentino" />        
        <meta name="twitter:description" content="Ask us about our menu, concerns, or enquiries about larger orders!" />        
        <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta property="og:title" content="Contact - Dal Fiorentino" />        
        <meta property="og:description" content="Ask us about our menu, concerns, or enquiries about larger orders!" />        
        <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
        <meta property="og:site_name" content="Contact - Dal Fiorentino" />
        <meta property="og:locale" content="en_UK" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="Dal Fiorentino, Italian flatbreads, schiacciate, Tuscan bread, traditional Italian shop, authentic Italian food, schiacciata London, Italian bakery, Italian cuisine London, fresh flatbreads, Tuscany specialties" />
      </Helmet>
      <Navbar />
      <div className='menu-container'>
        <div className='contact-container'>
          <br /><br />
          <h2 className='menu-title'>CONTACT US</h2>
          <p className='menu-welcome'>
            Ask us about our menu, concerns, or enquiries about larger orders!
          </p>
          <br /><br />
          
          <p className='contact-description'>
            You can reach us by phone, email, or by filling out our contact form on our website. Our team is available to assist you and will do its best to respond to your inquiry as soon as possible.
          </p>
          <p className='contact-description'>
            We look forward to hearing from you and hope to see you soon at our shop, where you can taste the authentic flavours of Florence in the heart of London.
          </p>
          <br />
          <p className='contact-ending'><em>Arrivederci!</em></p>
          <br /><br />
          <div className='contact-info'>
            <div className='contact-info-item'>
              <i className='fas fa-map-marker-alt'></i>
              <p>Hoxton | 74 Hoxton St, London N1 6LP</p>
            </div>
            <div className='contact-info-item'>
              <i className='fas fa-map-marker-alt'></i>
              <p>Fitzrovia | 15 Great Titchfield St., London W1W 8AZ</p>
            </div>
            <div className='contact-info-item'>
              <i className='fas fa-map-marker-alt'></i>
              <p>Brick Lane | 6 Cheshire Street, London E2 6EH</p>
            </div>
            <div className='contact-info-item'>
              <i className='fas fa-phone'></i>
              <p>+44 07783 437620</p>
            </div>
            <div className='contact-info-item'>
              <i className='fas fa-envelope'></i>
              <p>hello@dalfiorentino.london</p>
            </div>
          </div>
          <br /><br />
          <div className='contact-form'>
            <div className='contact-form-group'>
              <label htmlFor='name'>Name</label>
              <input type='text' id='name' name='name' value={name} onChange={(e) => {setName(e.target.value)}} required />
            </div>
            <div className='contact-form-group'>
              <label htmlFor='subject'>Subject</label>
              <input type='text' id='subject' name='subject' value={subject} onChange={(e) => {setSubject(e.target.value)}} required />
            </div>
            <div className='contact-form-group'>
              <label htmlFor='email'>Email</label>
              <input type='email' id='email' name='email' value={email} onChange={(e) => {setEmail(e.target.value)}} required />
            </div>
            <div className='contact-form-group'>
              <label htmlFor='message'>Message</label>
              <textarea id='message' name='message' value={message} rows='5' onChange={(e) => {setMessage(e.target.value)}} required />
            </div>
            <button className='contact-button' onClick={handleSendMessage}>Send</button>
          </div>
          <br /><br />
          <div className='contact-map-container'>
            {stores.map((store) => {
              return (
                <div className='contact-map' style={{ textAlign: 'center' }}>
                  <p><b>📍{store.name}</b></p>
                  <p style={{ fontSize: '16px' }}>{store.openingTimes}</p>
                  <br />
                  <iframe
                    src={store.embed_a_map}
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    title="Our Location"
                  ></iframe>
                  <br /><br />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Footer />
      {/* Render the FeedbackPopup */}
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
        showCancel={false} // Only show "Close" button
      />
    </div>
  );
}

export default Contact;