import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import NotFound from '../../NotFound';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import '../../../styles/admin/Forms.css';

function New() {
  const { user } = useAuth();

  const [loadedCategories, setLoadedCategories] = useState([]);
  const [userData, setUserData] = useState();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [weightInGrams, setWeightInGrams] = useState(0);
  const [priceInCents, setPriceInCents] = useState(0);
  const [available, setAvailable] = useState(true);
  const [visible, setVisible] = useState(true);
  /*Only to be used for sliced meats and cheeses*/
  const [pricePerKgInCents, setPricePerKgInCents] = useState(0);
  const [images, setImages] = useState();
  /*Only to be used for merchandise*/
  const [availableSizes, setAvailableSizes] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/categories', {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setLoadedCategories(response.data.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoriesChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setCategories(selectedOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    categories.forEach((category, index) => {
      formData.append(`categories[${index}]`, category);
    });
    formData.append('weightInGrams', weightInGrams);
    formData.append('priceInCents', priceInCents);
    formData.append('pricePerKgInCents', pricePerKgInCents);
    formData.append('available', available);
    formData.append('visible', visible);
  
    // Check if no sizes are selected
    if (availableSizes.length === 0) {
      console.log(categories)
      // Set available size based on category condition
      if (categories.includes("671122fe0b49bc81594988a0")) {
        formData.append('availableSizes', 'sold out');
      } else {
        formData.append('availableSizes', 'N/A');
      }
    } else {
      // Append each available size separately
      availableSizes.forEach((size, index) => {
        formData.append(`availableSizes[${index}]`, size);
      });
    }
  
    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i]);
      }
    }
  
    try {
      await axios.post(process.env.REACT_APP_API_URL + '/product/new', formData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
          'Content-Type': 'multipart/form-data',
        }
      });
      setFeedbackMessage("Product created successfully!");
    } catch (error) {
      console.error('Error submitting form:', error);
      setFeedbackMessage("Failed to create product. Please try again.");
    } finally {
      setIsPopupOpen(true);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <div className='form-container'>
          <h1>New Product</h1>
          <br /><br />
          <form onSubmit={handleSubmit}>
            <p>Visible</p>
            <br />
            <select 
              className='form-input'  
              onChange={(e) => setVisible(e.target.value)} 
            >
              <option value={1}>Yes</option>
              <option value={0}>No</option>
            </select>
            <br />
            <p>Availability</p>
            <br />
            <select 
              className='form-input'  
              onChange={(e) => setAvailable(e.target.value)} 
            >
              <option value={1}>Available</option>
              <option value={0}>Unavailable</option>
            </select>
            <br />
            <p>Name</p>
            <br />
            <input 
              type='text' 
              className='form-input' 
              placeholder='Name' 
              onChange={(e) => setName(e.target.value)} 
            />
            <br /><br />
            <p>Description</p>
            <br />
            <textarea 
              className='form-input' 
              placeholder='Description' 
              onChange={(e) => setDescription(e.target.value)} 
            />
            <br /><br />
            <p>Categories</p>
            <br />
            <select 
              className='form-input' 
              multiple 
              onChange={handleCategoriesChange} 
            >
              {loadedCategories.map(category => (
                <option key={category.id} value={category._id}>{category.name}</option>
              ))}
            </select>
            <br /><br />
            {/* If categories length is 0, show nothing; otherwise, display the appropriate inputs */}
            {categories.length === 0 ? null : (
              /* MERCHANDISE */
              categories.includes('671122fe0b49bc81594988a0') ? (
                <>
                  <p>Available Sizes</p>
                  <br />
                  {['XS', 'S', 'M', 'L', 'XL'].map((size) => (
                    <label key={size} style={{ marginRight: '10px' }}>
                      <input
                        type='checkbox'
                        value={size}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAvailableSizes((prev) => [...prev, size]);
                          } else {
                            setAvailableSizes((prev) => prev.filter((s) => s !== size));
                          }
                        }}
                      />
                      {size}
                    </label>
                  ))}
                  <br /><br />
                  <p>Weight (g)</p>
                  <br />
                  <input
                    type='number'
                    className='form-input'
                    placeholder='Weight (g)'
                    onChange={(e) => setWeightInGrams(e.target.value)}
                  />
                  <br /><br />
                  <p>Price (cents)</p>
                  <br />
                  <input
                    type='number'
                    className='form-input'
                    placeholder='Price (cents)'
                    onChange={(e) => setPriceInCents(e.target.value)}
                  />
                  <br /><br />
                </>
              ) : (
                !categories.includes('66b122598ef12d3980d89389') ? (
                  /* WHOLE PRODUCTS */
                  <>
                    <p>Weight (g)</p>
                    <br />
                    <input
                      type='number'
                      className='form-input'
                      placeholder='Weight (g)'
                      onChange={(e) => setWeightInGrams(e.target.value)}
                    />
                    <br /><br />
                    <p>Price (cents)</p>
                    <br />
                    <input
                      type='number'
                      className='form-input'
                      placeholder='Price (cents)'
                      onChange={(e) => setPriceInCents(e.target.value)}
                    />
                    <br /><br />
                  </>
                ) : (
                  /* SLICED PRODUCTS */
                  <>
                    <p>Price per KG (cents)</p>
                    <br />
                    <input
                      type='number'
                      className='form-input'
                      placeholder='Price per KG (cents)'
                      onChange={(e) => setPricePerKgInCents(e.target.value)}
                    />
                    <br /><br />
                  </>
                )
              )
            )}
            <p>Images (Square)</p>
            <br />
            <input 
              type='file' 
              className='form-input' 
              onChange={(e) => setImages(e.target.files)} 
              multiple 
            />
            <br /><br />
            <button type='submit' className='button'>Create</button>
          </form>
        </div>
      </div>

      <Footer />

      {/* Render the FeedbackPopup */}
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
        showCancel={false} // Only show "Close" button
      />
    </>
  );
}

export default New;
