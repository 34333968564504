import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import '../styles/Menu.css';

function Menu() {
  const { category } = useParams();

  const [menuItems, setMenuItems] = useState([]);
  const [loadingMenuItems, setLoadingMenuItems] = useState(true);

  useEffect(() => {
    setLoadingMenuItems(true);
    if(category) {
      const fetchMenuItems = async () => {
        try {
          let menuItems = [];
      
          // Fetch vegetarian and vegan menu items if the category is either 'vegetarian' or 'vegan'
          if (category === 'vegetarian' || category === 'vegan') {
            const vegetarianResponse = await axios.get(`${process.env.REACT_APP_API_URL}/menu-items/category/vegetarian`, {
              headers: {
                'X-API-KEY': process.env.REACT_APP_API_KEY,
              },
            });
      
            const veganResponse = await axios.get(`${process.env.REACT_APP_API_URL}/menu-items/category/vegan`, {
              headers: {
                'X-API-KEY': process.env.REACT_APP_API_KEY,
              },
            });
      
            // Combine vegetarian and vegan menu items
            menuItems = [...vegetarianResponse.data.menuItems, ...veganResponse.data.menuItems];
          } else {
            // Fetch menu items for the selected category
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/menu-items/category/${category}`, {
              headers: {
                'X-API-KEY': process.env.REACT_APP_API_KEY,
              },
            });
            menuItems = response.data.menuItems;
          }
      
          setMenuItems(menuItems);
          setLoadingMenuItems(false);
        } catch (error) {
          console.error('Error fetching menu items:', error);
        }
      };
      
      fetchMenuItems();      
    } else {
      const fetchMenuItems = async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_API_URL + `/menu-items`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
          });
          setMenuItems(response.data.menuItems);
          setLoadingMenuItems(false);
        } catch (error) {
          console.error('Error fetching menu items:', error);
        }
      };
  
      fetchMenuItems();
    }
  }, [category]);

  return (
    <div style={{ backgroundColor: '#ececec' }}>
      <Helmet>
        <title>Menu - Dal Fiorentino</title>
        <meta name="description" content="Explore the menu at Dal Fiorentino London! Discover our authentic Italian flatbreads, fresh schiacciate, and other Tuscan specialties made with the finest ingredients." />        
        <meta name="twitter:card" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta name="twitter:site" content="@dalfiorentino.london" />        
        <meta name="twitter:creator" content="@dalfiorentino.london" />        
        <meta name="twitter:title" content="Menu - Dal Fiorentino" />        
        <meta name="twitter:description" content="Explore the menu at Dal Fiorentino London! Discover our authentic Italian flatbreads, fresh schiacciate, and other Tuscan specialties made with the finest ingredients." />        
        <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta property="og:title" content="Menu - Dal Fiorentino" />        
        <meta property="og:description" content="Explore the menu at Dal Fiorentino London! Discover our authentic Italian flatbreads, fresh schiacciate, and other Tuscan specialties made with the finest ingredients." />        
        <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
        <meta property="og:site_name" content="Menu - Dal Fiorentino" />
        <meta property="og:locale" content="en_UK" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="Dal Fiorentino, Italian flatbreads, schiacciate, Tuscan bread, traditional Italian shop, authentic Italian food, schiacciata London, Italian bakery, Italian cuisine London, fresh flatbreads, Tuscany specialties" />
      </Helmet>
      <Navbar />
      <div className='menu-container'>
        <br /><br />
        <h2 className='menu-title'>MENU</h2>
        <p className='menu-welcome'>Freshly made on our schiacciata bread.</p>
        <br /><br />
        {!loadingMenuItems && 
          Object.entries(menuItems.reduce((groups, item) => {
            // Group items by category
            if (!groups[item.category]) {
              groups[item.category] = []; // Create a new group for the category
            }
            groups[item.category].push(item); // Add item to the respective group
            return groups;
          }, {}))
          .sort(([categoryA], [categoryB]) => {
            // Move "sweet" category to the end
            if (categoryA === 'sweet') return 1;
            if (categoryB === 'sweet') return -1;
            return 0;
          })
          .map(([category, items]) => (
            <div key={category}>
              <h2 className='menu-category-name'>{category.toUpperCase()}</h2> {/* Title the section with the category name */}
              <br /><br />
              {items.reduce((rows, item, index) => {
                // Create a new row every two items
                if (index % 2 === 0) {
                  rows.push([]); // Start a new row
                }
                // Add the current item to the last row
                rows[rows.length - 1].push(item);
                return rows;
              }, []).map((row, rowIndex) => (
                <div className='row' key={rowIndex}>
                  {row.map(item => (
                    <div className='menu-column' key={item.id}> {/* Assuming item has a unique id */}
                      <img src={`${process.env.REACT_APP_API_URL}/${item.images[0]}`} alt={item.name} />
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h2>{item.name.toUpperCase()} {item.category === 'vegan' && '(VE)'} {item.category === 'vegetarian' && '(VG)'}</h2>
                        <h2>{item.pricesInCents.small != 0 ? '£' + item.pricesInCents.small / 100 + ' (S)' : null} {item.pricesInCents.large != 0 ? '£' + item.pricesInCents.large / 100 + ' (L)' : null}</h2>
                      </div>
                      <p>{item.description}</p>
                      <br />
                    </div>
                  ))}
                </div>
              ))}
              <br /><br />
            </div>
          ))
        }
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default Menu;
