import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import NewsletterBox from '../components/widgets/NewsletterBox';
import '../styles/Home.css';

function Home() {
  const [menuItems, setMenuItems] = useState([]);
  const [loadingMenuItems, setLoadingMenuItems] = useState(true);

  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  useEffect(() => {
    setLoadingMenuItems(true);
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/menu-items', {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setMenuItems(response.data.menuItems);
        setLoadingMenuItems(false);
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    };

    fetchMenuItems();
  }, []);

  useEffect(() => {
    setLoadingCategories(true);
    const fetchCategories = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/categories', {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setCategories(response.data.categories);
        setLoadingCategories(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <>
      <Helmet>
        <title>Home - Dal Fiorentino</title>
        <meta name="description" content="Dal Fiorentino London is a traditional Italian shop, offering authentic Italian flatbreads from Tuscany. Try our delicious and fresh schiacciate today!" />        
        <meta name="twitter:card" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta name="twitter:site" content="@dalfiorentino.london" />        
        <meta name="twitter:creator" content="@dalfiorentino.london" />        
        <meta name="twitter:title" content="Dal Fiorentino | Italian Flatbreads" />        
        <meta name="twitter:description" content="Dal Fiorentino London is a traditional Italian shop, offering authentic Italian flatbreads from Tuscany. Try our delicious and fresh schiacciate today!" />        
        <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />        
        <meta property="og:title" content="Dal Fiorentino | Italian Flatbreads" />        
        <meta property="og:description" content="Dal Fiorentino London is a traditional Italian shop, offering authentic Italian flatbreads from Tuscany. Try our delicious and fresh schiacciate today!" />        
        <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/images/about-1.jpg`} />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
        <meta property="og:site_name" content="Dal Fiorentino | Italian Flatbreads" />
        <meta property="og:locale" content="en_UK" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="Dal Fiorentino, Italian flatbreads, schiacciate, Tuscan bread, traditional Italian shop, authentic Italian food, schiacciata London, Italian bakery, Italian cuisine London, fresh flatbreads, Tuscany specialties" />
      </Helmet>
      <Navbar />
      <br /><br /><br />
      <div className='home-hero-section'>
        <h1 className='home-hero-title'>SCHIACCIATE FIORENTINE</h1>
        <p className='home-hero-subtitle'>Freshly Baked Daily in Store</p>
        <br />
        <img className='home-hero-img' src={process.env.REACT_APP_API_URL + `/images/home-3-schiacciate-2.png`} />
      </div>
      <div className='global-container' style={{ textAlign: 'center' }}>
        <br />
        <img src={`${process.env.REACT_APP_API_URL + '/images/giglio-line.png'}`} className='giglio-line' />
        <section className='home-product-showcase'>
          <a href={`/menu`}>
            <h2 style={{ color: '#c52d12', fontSize: '50px', fontWeight: '800' }}>MENU</h2>
          </a>
          <p className='caption'>For larger orders, please reach out to us via the <a href='/catering' style={{ color: '#c52d12' }}>Catering Request</a> page.</p>
          <br /><br />
          <div className='row'>
            <a href='/menu'>
              <div className='home-menu-card'>
                <img src={`${process.env.REACT_APP_API_URL}/images/uploads/1729256647968--dante.jpg`} />
                <h3>MEAT</h3>
                <p>Available in-store & delivery</p>
                <br />
                <button className='button'>View Menu</button>
              </div>
            </a>
            <a href='/menu'>
              <div className='home-menu-card'>
                <img src={`${process.env.REACT_APP_API_URL}/images/uploads/1729256936812--il-magnifico.jpg`} />
                <h3>VEGGIE / VEGAN</h3>
                <p>Available in-store & delivery</p>
                <br />
                <button className='button'>View Menu</button>
              </div>
            </a>
            <a href='/menu'>
              <div className='home-menu-card'>
                <img src={`${process.env.REACT_APP_API_URL}/images/uploads/1729256708429--nutella.jpg`} />
                <h3>SWEET</h3>
                <p>Available in-store & delivery</p>
                <br />
                <button className='button'>View Menu</button>
              </div>
            </a>
          </div>
        </section>
        <br /><br />
        <NewsletterBox />
        <br /><br /><br /><br />
        <img src={`${process.env.REACT_APP_API_URL + '/images/giglio-line.png'}`} className='giglio-line' />
        <br /><br />
        <section className='home-product-showcase'>
          <a href={`/shop`}>
            <h2 style={{ color: '#c52d12', fontSize: '50px', fontWeight: '800' }}>BUY OUR PRODUCTS</h2>
          </a>
          {!loadingCategories && (
            <div className='row'>
              {!loadingCategories && (
                <div className='row'>
                  {categories.map((category, index) => (
                    <div className='home-categories-column' key={index}>
                      <a href={`/shop/${category.slug}`}>
                        <div className='home-categories-image-container'>
                          <img src={`${process.env.REACT_APP_API_URL}/${category.images[0]}`} alt={category.name} />
                          <div className='home-categories-centered-text'>{category.name}</div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </section>
        <img src={`${process.env.REACT_APP_API_URL + '/images/giglio-line.png'}`} className='giglio-line' />
        <br /><br />
        <section className='home-featured-product'>
          <h2 style={{ color: '#c52d12', fontSize: '50px', fontWeight: '800' }}>OUR SCHIACCIATA</h2>
          <div className='home-featured-content'>
            <img src={process.env.REACT_APP_API_URL + `/images/schiacciata-t-1.png`} alt='Schiacciata' />
            <div className='home-featured-description'>
              <p>Our Schiacciata is a beloved Florentine flatbread, freshly baked in-store daily. Experience the authentic taste of Florence right here in London. Available exclusively in-store.</p>
              <button className='button' onClick={() => window.location.href = '/contact'}>Visit Us</button>
            </div>
          </div>
        </section>
        {/* <div className='row'>
          <div className='home-online-delivery-column'>
            <a href={'https://deliveroo.co.uk/menu/london/hoxton/dal-fiorentino-hoxton-street?srsltid=AfmBOorQkhiLyLER8JOyUdTWIyWPytn_e01shzmbAXAOfXm57hgD8cX9'}>
              <img src={`${process.env.REACT_APP_API_URL}/images/deliveroo.png`} alt={'Deliveroo Logo'} />
            </a>
          </div>
          <div className='home-online-delivery-column'>
            <a href={'https://www.ubereats.com/gb/store/dal-fiorentino/k2o-x2udRvi1WDWSKB3XQg?srsltid=AfmBOooZVNxkcnVFvogaWiEkLLCcDKL7XUK77CLnj52TOftW_Lgn8I-f'}>
              <img src={`${process.env.REACT_APP_API_URL}/images/uber-eats.png`} alt={'Uber Eats Logo'} />
            </a>
          </div>
          <div className='home-online-delivery-column'>
            <a href={'https://www.just-eat.co.uk/restaurants-dal-fiorentino-islington/menu'}>
              <img src={`${process.env.REACT_APP_API_URL}/images/just-eat.png`} alt={'Just Eat Logo'} />
            </a>
          </div>
        </div> */}
      </div>
      <Footer />
    </>
  );
}

export default Home;
