import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import NotFound from '../../NotFound';
import '../../../styles/admin/Forms.css';

function Edit() {
  const { user } = useAuth();
  const { menuItemId } = useParams();

  const [userData, setUserData] = useState();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("meat");
  const [pricesInCents, setPricesInCents] = useState({ small: 0, large: 0 });
  const [images, setImages] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchMenuItemData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/menu-item/${menuItemId}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        const menuItem = response.data.menuItem;
        setName(menuItem.name);
        setDescription(menuItem.description);
        setCategory(menuItem.category);
        setPricesInCents(menuItem.pricesInCents);
        setImages(menuItem.images);
      } catch (error) {
        console.error('Error fetching menuItem:', error);
      }
    };

    fetchMenuItemData();
  }, [menuItemId]);

  const handlePricesChange = (size, value) => {
    setPricesInCents(prevState => ({
      ...prevState,
      [size]: parseInt(value, 10),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('category', category);
    formData.append('pricesInCents', JSON.stringify(pricesInCents));
    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i]);
      }
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/menu-item/${menuItemId}`, formData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
          'Content-Type': 'multipart/form-data',
        }
      });
      setFeedbackMessage("MenuItem updated successfully!"); // Set success message
    } catch (error) {
      console.error('Error submitting form:', error);
      setFeedbackMessage("Failed to update menuItem. Please try again."); // Set error message
    } finally {
      setIsPopupOpen(true); // Open the popup regardless of success or failure
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <div className='form-container'>
          <h1>Edit Menu Item</h1>
          <br /><br />
          <form onSubmit={handleSubmit}>
            <p>Name</p>
            <br />
            <input 
              type='text' 
              className='form-input' 
              value={name}
              placeholder='Name' 
              onChange={(e) => setName(e.target.value)} 
            />
            <br /><br />
            <p>Description</p>
            <br />
            <textarea 
              className='form-input' 
              value={description}
              placeholder='Description' 
              onChange={(e) => setDescription(e.target.value)} 
            />
            <br /><br />
            <p>Category</p>
            <br />
            <select 
              className='form-input' 
              value={category}
              onChange={(e) => setCategory(e.target.value)} 
            >
              <option value='meat'>Meat</option>
              <option value='vegetarian'>Vegetarian</option>
              <option value='vegan'>Vegan</option>
              <option value='sweet'>Sweet</option>
            </select>
            <br /><br />
            <p>Price (Small)</p>
            <br />
            <input 
              type='number' 
              className='form-input' 
              value={pricesInCents.small}
              placeholder='Price (cents)' 
              onChange={(e) => handlePricesChange('small', e.target.value)} 
            />
            <br /><br />
            <p>Price (Large)</p>
            <br />
            <input 
              type='number' 
              className='form-input' 
              value={pricesInCents.large}
              placeholder='Price (cents)' 
              onChange={(e) => handlePricesChange('large', e.target.value)} 
            />
            <br /><br />
            <p>Images (Square)</p>
            <br />
            <input 
              type='file' 
              className='form-input' 
              onChange={(e) => setImages(e.target.files)} 
              multiple 
            />
            <br /><br />
            <button type='submit' className='button'>Update</button>
          </form>
        </div>
      </div>

      <Footer />

      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
      />
    </>
  );
}

export default Edit;
