import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';

function PaymentSuccess() {
  const { user } = useAuth();

  const { orderId } = useParams();

  const [userData, setUserData] = useState();
  const [loadingUserData, setLoadingUserData] = useState(true);

  const [orderData, setOrderData] = useState();
  const [loadingOrder, setLoadingOrder] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoadingUserData(true);
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
        setLoadingUserData(false);
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoadingUserData(false);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchOrder = async () => {
      if (!userData) return;

      try {
        setLoadingOrder(true);

        console.log(orderId)

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/order`, {
          user: userData._id,
          orderId
        }, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });

        setOrderData(response.data.order); // Store order data
        setLoadingOrder(false);
      } catch (error) {
        console.error('Error fetching order:', error);
        setLoadingOrder(false);
      }
    };

    fetchOrder();
  }, [userData]);

  return (
    <div>
      <Navbar />
      <div className='global-container' style={{ textAlign: 'center' }}>
        <i className={'fas fa-check'} style={{ fontSize: '70px', color: 'green' }} />
        <br /><br /><br />
        <h1>Payment Successful!</h1>
        <br />
        <h3>Thank you for chosing Dal Fiorentino!</h3>
        <br /><br />
        {loadingOrder ? (
          <p>Loading order details...</p>
        ) : orderData ? (
          <div>
            <h2>Order Details:</h2>
            <br />
            <p>Order Number: #{orderData.orderNumber}</p>
            <br />
            <p>Please check you e-mail for further details!</p>
            <br /><br /><br />
            <p>Support e-mail: shop@dalfiorentino.london</p>
          </div>
        ) : (
          <p>Order details could not be retrieved.</p>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PaymentSuccess;