import React from 'react';
import Modal from 'react-modal';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    maxWidth: '400px',
    width: '100%',
  },
};

const buttonStyles = {
  marginTop: '20px',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '10px',
  backgroundColor: '#c52d12',
  color: '#fff',
  cursor: 'pointer',
  fontSize: '16px',
};

const FeedbackPopup = ({ isOpen, message, onClose, onConfirm, confirmButtonText, isAdmin }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Feedback Popup"
      style={modalStyles}
      ariaHideApp={false} // to prevent accessibility errors
    >
      <div>
        <h2>{message}</h2>
        {isAdmin ? (
          <>
            <button style={buttonStyles} onClick={onConfirm}>{confirmButtonText || "Confirm"}</button>
            <button style={{ ...buttonStyles, marginLeft: '10px', backgroundColor: '#ccc', color: '#000' }} onClick={onClose}>Cancel</button>
          </>
        ) : (
          <button style={buttonStyles} onClick={onClose}>Close</button>
        )}
      </div>
    </Modal>
  );
};

export default FeedbackPopup;