import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import NotFound from '../../NotFound';
import '../../../styles/admin/Forms.css';

function View() {
  const { user } = useAuth();

  const [userData, setUserData] = useState();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [categories, setCategories] = useState([]);
  const [laodingCategories, setLoadingCategories] = useState(true);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    setLoadingCategories(true);
    const fetchCategories = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/categories', {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setCategories(response.data.categories);
        setLoadingCategories(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, []);

  const handleDeleteConfirmation = (categoryId) => {
    setDeleteCategoryId(categoryId); // Set the category ID to delete
    setIsPopupOpen(true); // Open the confirmation popup
  };

const confirmDelete = async () => {
  if (deleteCategoryId) {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/category/${deleteCategoryId}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
        },
      });

      // If the deletion was successful, update the state
      setCategories(currentCategories =>
        currentCategories.filter(category => category._id !== deleteCategoryId)
      );

      setFeedbackMessage("Category deleted successfully!"); // Set success message

    } catch (error) {
      console.error('Error deleting category:', error);

      // Check for the specific error message from the server and display it in an alert
      const errorMessage = error.response?.data?.message || "Failed to delete category. Please try again.";
      alert(errorMessage);  // Display error message from server in an alert
    } finally {
      setIsPopupOpen(false); // Close the popup
    }
  }
};

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <h1 style={{ textAlign: 'center' }}>All Categories</h1>
        <br />
        <p style={{ textAlign: 'center' }}>View and Edit Categories</p>
        <br /><br />
        <table className='table'>
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Edit</th>
              {/* <th>Delete</th> */}
            </tr>
          </thead>
          <tbody>
            {categories.map(category => (
              <tr key={category._id}>
                <td><img src={`${process.env.REACT_APP_API_URL}/${category.images[0]}`} /></td>
                <td>{category.name}</td>
                <td><Link to={`/admin/category/edit/${category._id}`}><button className='button'>Edit</button></Link></td>
                {/* <td><button className='button' onClick={() => handleDeleteConfirmation(category._id)}>Delete</button></td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Footer />

      <FeedbackPopup
        isOpen={isPopupOpen}
        message={"Are you sure you want to delete this category?"}
        onClose={closePopup}
        onConfirm={confirmDelete}
        confirmButtonText="Confirm Deletion"
        isAdmin={userData && userData.type === 'admin'} // Pass isAdmin prop based on user role
      />
    </>
  );
}

export default View;
