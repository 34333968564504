import React from 'react';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';

function NotFound() {
  return (
    <div>
      <Navbar />
      <div className='global-container' style={{ textAlign: 'center' }}>
        <h2>The page you're looking for has been already baked or does not exist!</h2>
      </div>
      <Footer />
    </div>
  );
}

export default NotFound;