import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../components/AuthContext';
import '../../styles/components/common/Navbar.css';

function Navbar() {
  const { user } = useAuth();

  const [userData, setUserData] = useState();
  const [basketItems, setBasketItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    if (user) fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchBasketItems = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/basket/user/${userData._id}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setBasketItems(response.data.basketItems);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchBasketItems();
  }, [userData]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>      
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Dal Fiorentino",
            "url": "https://dalfiorentino.london"
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              { "@type": "ListItem", "position": 1, "name": "Home", "item": "https://dalfiorentino.london" },
              { "@type": "ListItem", "position": 2, "name": "Menu", "item": "https://dalfiorentino.london/menu" },
              { "@type": "ListItem", "position": 3, "name": "Catering", "item": "https://dalfiorentino.london/catering" },
              { "@type": "ListItem", "position": 4, "name": "About", "item": "https://dalfiorentino.london/about" },
              { "@type": "ListItem", "position": 5, "name": "Contact", "item": "https://dalfiorentino.london/contact" }
            ]
          })}
        </script>
      </Helmet>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="menu-icon" onClick={toggleMenu}>
            <i className={isOpen ? 'fas fa-times menu-icon-black' : 'fas fa-bars'}></i>
          </div>
          <Link to="/" className="navbar-logo">
            <img src="/logo_white_2.png" alt="Logo" />
          </Link>
          {/* <Link to="/menu" className="navbar-cart">
            <div className="navbar-cart-container">
              <i className="fas fa-utensils navbar-cart"></i>
              {basketItems.length > 0 && (
                <span className="basket-badge">{basketItems.length}</span>
              )}
            </div>
          </Link> */}
          <Link to="/basket" className="navbar-cart">
            <div className="navbar-cart-container">
              <i className="fas fa-shopping-basket navbar-cart"></i>
              {basketItems.length > 0 && (
                <span className="basket-badge">{basketItems.length}</span>
              )}
            </div>
          </Link>
        </div>
        <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
        <li className="nav-item">
            <Link to="/menu" className="nav-links" onClick={toggleMenu}>Menu</Link>
          </li>
          <li className="nav-item">
            <Link to="/shop" className="nav-links" onClick={toggleMenu}>Shop</Link>
          </li>
          <li className="nav-item">
            <Link to="/catering" className="nav-links" onClick={toggleMenu}>Catering</Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-links" onClick={toggleMenu}>About</Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={toggleMenu}>Contact</Link>
          </li>
          {user && (
            <li className="nav-item">
              <Link to="/basket" className="nav-links" onClick={toggleMenu}>
                <div className="navbar-cart-container">
                  <i className="fas fa-shopping-basket nav-social-link"></i>
                  {basketItems.length > 0 && (
                    <span className="basket-badge">{basketItems.length}</span>
                  )}
                </div>
              </Link>
            </li>
          )}
          <li className="nav-item">
            <Link to="https://instagram.com/dalfiorentino.london" className="nav-links" onClick={toggleMenu}>
              <i className="fab fa-instagram nav-social-link"></i>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={user ? "/account" : "/login"} className="nav-links" onClick={toggleMenu}>
              {user ? (
                <img
                  src={user.photoURL || "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"}
                  style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                  alt="User Profile"
                />
              ) : (
                <i className="fas fa-user nav-social-link"></i>
              )}
            </Link>
          </li>
          {userData && userData.type === 'admin' && (
            <li className="nav-item">
              <Link to="/admin" className="nav-links" onClick={toggleMenu}>
                <i className="fas fa-cube nav-social-link"></i>
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
