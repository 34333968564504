import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import ProductCard from '../components/ProductCard';
import SliderCategories from '../components/widgets/SliderCategories';
import ClipLoader from 'react-spinners/ClipLoader'; // Import a spinner
import '../styles/Category.css';

function Shop() {
  const { categorySlug } = useParams();

  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState();

  const [products, setProducts] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    setLoadingProducts(true);
    const fetchAllProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setProducts(response.data.products.filter(product => product.visible));
        setLoadingProducts(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoadingProducts(false); // Ensure loading state is updated on error
      }
    };

    fetchAllProducts();
  }, []);

  useEffect(() => {
    setLoadingCategories(true);
    axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
      headers: {
        'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
      }
    })
    .then((response) => {
      setCategories(response.data.categories);
      setLoadingCategories(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoadingCategories(false);
    });

    if(categorySlug) {
      axios.get(`${process.env.REACT_APP_API_URL}/category/slug/${categorySlug}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY // Include the API key in the request headers
        }
      })
      .then(async (response) => {
        setSelectedCategory(response.data.category);
        const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/product/category/${response.data.category._id}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setProducts(response2.data.products.filter(product => product.visible));
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    }
  }, [categorySlug]);

  // Function to split products into chunks of 4
  const chunkArray = (arr, chunkSize) => {
    if(arr) {
      const chunks = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
      }
      return chunks;
    }
  };

  return (
    <>
      <Navbar />
      <div className="navbar-bottom">
        <div className="search-form">
          <input
            type="text"
            className="search-input"
            placeholder="Search..."
            onChange={(e) => setSearchString(e.target.value)}
          />
          <a href={`${process.env.REACT_APP_BASE_URL}/search/${searchString}`}>
            <button className="search-button">Search</button>
          </a>
        </div>
      </div>
      {!loadingProducts && !loadingCategories ? (
        <>
          <br />
          <SliderCategories categories={categories} />
          <br />
          <div className="global-container">
            <h1>{selectedCategory ? selectedCategory.name : 'Shop all'}</h1>
            <br />
            <p>Shop {selectedCategory ? selectedCategory.name : 'all'} products</p>
            <br />
            {chunkArray(products, 4).map((productRow, rowIndex) => (
              <div className='row' key={rowIndex}>
                {productRow.map((product, index) => (
                  <div className='category-product-column' key={index}>
                    <ProductCard 
                      id={product._id} 
                      name={product.name} 
                      img={`${process.env.REACT_APP_API_URL}/${product.images[0]}`} 
                      weightInGrams={product.weightInGrams} 
                      pricePerKgInCents={product.pricePerKgInCents} 
                      availableSizes={product.availableSizes}
                      available={product.available}
                      description={`£${product.pricePerKgInCents === 0 ? product.priceInCents / 100 : product.pricePerKgInCents / 1000 + ' / 100g '}`} 
                      slug={product.slug}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="loading-container">
          <ClipLoader size={50} color="#007bff" loading={true} />
        </div>
      )}
      <Footer />
    </>
  );
}

export default Shop;
