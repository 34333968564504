import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import NotFound from '../../NotFound';
import '../../../styles/admin/Forms.css';

function Edit() {
  const { user } = useAuth();
  const { orderId } = useParams();

  const [stores, setStores] = useState([]);
  const [userData, setUserData] = useState();

  const [store, setStore] = useState(""); // Default to empty string for controlled component
  const [selectedDate, setSelectedDate] = useState(""); // Default to empty string for controlled component

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/stores`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setStores(response.data.stores);
      } catch (error) {
        console.error('Error fetching stores:', error);
      }
    };

    fetchStores();
  }, []);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/order/${orderId}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        const order = response.data.order;
        setStore(order.store._id); // Set default store from order
        setSelectedDate(order.selectedDate); // Set default date from order
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };

    fetchOrderData();
  }, [orderId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!store || !selectedDate) {
      setFeedbackMessage("Please select both a store and a date.");
      setIsPopupOpen(true);
      return;
    }
  
    const formData = new FormData();
    formData.append('store', store);
    formData.append('selectedDate', new Date(selectedDate));
  
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/order/${orderId}`, formData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
      });
      setFeedbackMessage("Order updated successfully!");
    } catch (error) {
      console.error('Error submitting form:', error);
      setFeedbackMessage("Failed to update order. Please try again.");
    } finally {
      setIsPopupOpen(true);
    }
  };  

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage("");
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <div className='form-container'>
          <h1>Edit Order</h1>
          <br /><br />
          <form onSubmit={handleSubmit}>
            <p>Pick-up Store</p>
            <br />
            <select 
              className='form-input' 
              value={store} // Default value set to the state
              onChange={(e) => setStore(e.target.value)} 
            >
              {stores.map(store => (
                <option key={store._id} value={store._id}>{store.name}</option>
              ))}
            </select>
            <br /><br />
            <p>Pick-up Date</p>
            <br />
            <input 
              type="date" 
              className='form-input' 
              value={selectedDate ? selectedDate.substring(0, 10) : ""} // Default value set to the state
              onChange={(e) => setSelectedDate(e.target.value)} 
            />
            <br /><br />
            <button type='submit' className='button'>Update</button>
          </form>
        </div>
      </div>

      <Footer /> 

      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
      />
    </>
  );
}

export default Edit;
