import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ClipLoader from 'react-spinners/ClipLoader'; // Import a spinner
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import '../styles/Basket.css';

function Basket() {
  const { user } = useAuth();

  const [userData, setUserData] = useState();
  const [loadingUserData, setLoadingUserData] = useState(true);

  const [basketItems, setBasketItems] = useState([]);
  const [loadingBasketItems, setLoadingBasketItems] = useState(true);

  const [stores, setStores] = useState([]);
  const [store, setStore] = useState();

  const [selectedStore, setSelectedStore] = useState();

  const [discountCode, setDiscountCode] = useState();

  const [selectedDate, setSelectedDate] = useState(null);
  const [minSelectableDate, setMinSelectableDate] = useState(null);

  // Define the dates you want to disable
  const disabledDates = [
    new Date('2024-12-24'),
    new Date('2024-12-25'),
    new Date('2024-12-26'),
    new Date('2024-12-31'),
    new Date('2025-01-01'),
  ];

  const isDateDisabled = (date) => {
    return disabledDates.some(disabledDate => 
      date.getFullYear() === disabledDate.getFullYear() &&
      date.getMonth() === disabledDate.getMonth() &&
      date.getDate() === disabledDate.getDate()
    );
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoadingUserData(true);
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
        setLoadingUserData(false);
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoadingUserData(false);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/stores`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setStores(response.data.stores);
        setStore(response.data.stores[0]);
        setSelectedStore(response.data.stores[0]);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchStores();
  }, []);

  useEffect(() => {
    setLoadingBasketItems(true);
    const fetchBasketItems = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/basket/user/${userData._id}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setBasketItems(response.data.basketItems);
        setLoadingBasketItems(false);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchBasketItems();
  }, [userData]);

  // Script for CUT-OFF TIME
  useEffect(() => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const fivePm = new Date(today);
    fivePm.setHours(16, 0, 0, 0); // Set time to 5 PM

    let firstAvailableDate;
    if (now >= fivePm) {
      // After 5 PM: The first available date is the day after tomorrow
      firstAvailableDate = new Date(today);
      firstAvailableDate.setDate(today.getDate() + 2);
    } else {
      // Before 5 PM: The first available date is tomorrow
      firstAvailableDate = new Date(today);
      firstAvailableDate.setDate(today.getDate() + 1);
    }

    // Set the minimum selectable date and automatically select it
    setMinSelectableDate(firstAvailableDate);
    setSelectedDate(firstAvailableDate);
  }, []);

  const removeBasketItem = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/basket/${id}`, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
        },
      });

      setBasketItems(currentBasketItems =>
        currentBasketItems.filter(basketItem => basketItem._id !== id)
      );

    } catch (error) {
      console.error('Error deleting menuItem:', error);
    }
  };

  const handleQuantityChange = async (basketItemId, newQuantity) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/basket/${basketItemId}`, {
        quantity: newQuantity
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
        },
      });

      setBasketItems(currentBasketItems =>
        currentBasketItems.map(item =>
          item._id === basketItemId ? { ...item, quantity: newQuantity } : item
        )
      );
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  const handleStoreChange = (e) => {
    const fetchStore = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/store/${e.target.value}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setStore(response.data.store);
        setSelectedStore(response.data.store);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchStore();
  };

  const handleCheckout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/checkout`, {
        user: userData,
        store,
        selectedDate,
        discountCode,
        success_url: `${process.env.REACT_APP_BASE_URL}/payment/success`,
        cancel_url: `${process.env.REACT_APP_BASE_URL}/basket`
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
        },
      })
      .then((response) => {
        const checkoutUrl = response.data.url;
        window.location.href = checkoutUrl;
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  return (
    <>
      <Navbar />
      <div className='global-container'>
        {loadingBasketItems ? (
          <div className="loading-container">
            <ClipLoader size={50} color="#007bff" loading={true} />
          </div>
        ) : (
          <>
            <div className='row'>
              <div className='basket-left-column'>
                <h2 className='basket-title'>Shopping Basket</h2>
                <p className='basket-welcome'>All orders have to be collected in-store.</p>
                <br /><br />
                <hr />
                <br /><br />
                  {basketItems.map(basketItem => (
                    <>
                    <table className='basket-table'>
                      <tr key={basketItem._id}>
                        <td><img src={`${process.env.REACT_APP_API_URL}/${basketItem.product.images[0]}`} alt={basketItem.name} style={{ marginRight: '20px' }} /></td>
                        <td className='basket-fixed-width-td'>
                          <p><b>{basketItem.product.name}</b></p>
                          {basketItem.size === 'N/A' ? (
                            <p>Weight: {basketItem.grams === 0 ? (basketItem.product.weightInGrams > 999 ? basketItem.product.weightInGrams / 1000 + 'kg' : basketItem.product.weightInGrams + 'g') : basketItem.grams + 'g' }</p>
                          ) : (
                            <p>Size: {basketItem.size}</p>
                          )}
                          <br />
                          <div className='quantity'>
                            <p><b>QUANTITY</b></p>
                            <select
                              className='basket-input'
                              value={basketItem.quantity}
                              onChange={(e) => handleQuantityChange(basketItem._id, parseInt(e.target.value))}
                            >
                              {Array.from({ length: 10 }, (_, index) => (
                                <option key={index + 1} value={index + 1}>{index + 1}</option>
                              ))}
                            </select>
                          </div>
                        </td>
                      </tr>
                    </table>
                    <table className='basket-table'>
                      <tr style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <td>
                          <p onClick={() => {removeBasketItem(basketItem._id)}} style={{ cursor: 'pointer', fontSize: '15px' }}>Remove</p>
                        </td>
                        <td style={{ display: 'flex'}}>
                          <div style={{ marginRight: '10px' }}>
                            <p><b>EACH</b></p>
                            <p>£{basketItem.grams === 0 ? basketItem.product.priceInCents / 100 : ((basketItem.product.pricePerKgInCents / 100000) * basketItem.grams).toFixed(2)}</p>
                          </div>
                          <div>
                            <p><b>TOTAL</b></p>
                            <p>£{(basketItem.grams === 0 ? (basketItem.quantity * basketItem.product.priceInCents / 100) : basketItem.quantity * ((basketItem.product.pricePerKgInCents / 100000) * basketItem.grams)).toFixed(2)}</p>
                          </div>
                        </td>
                      </tr>
                      </table>
                      <br /><br /><br />
                    </>
                  ))}
                <br />
                <hr />
                <br />
                <div className='basket-total'>
                  <p>{basketItems.length} items</p>
                  <p>£{basketItems.reduce((accumulator, basketItem) => { return accumulator + (basketItem.grams === 0 ? (basketItem.quantity * basketItem.product.priceInCents / 100) : basketItem.quantity * ((basketItem.product.pricePerKgInCents / 100000) * basketItem.grams).toFixed(2) ); }, 0).toFixed(2)}</p>
                </div>
              </div>
              <div className='basket-right-column'>
                <p className='basket-title'><b>Checkout</b></p>
                <br />
                <p className='basket-welcome'>Step 1</p>
                <div className='basket-right-column-step-wrapper'>
                  <p className='basket-welcome'>Select pick-up store</p>
                  <select className='basket-input' onChange={(e) => handleStoreChange(e)}>
                    {stores.map(store => (
                      <option key={store._id} value={store._id}>{store.name}</option>
                    ))}
                  </select>
                  <br /><br />
                  <p className='basket-welcome'>Store opening times</p>
                  <p>{selectedStore.openingTimes}</p>
                </div>
                <br /><br />
                <p className='basket-welcome'>Step 2</p>
                <div className='basket-right-column-step-wrapper'>
                  <p className='basket-welcome'>Select pick-up date</p>
                  <p>Cut-off time for next day collection is 6pm</p>
                  <br />
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    inline // Keeps the calendar open by default
                    showMonthDropdown // Allows users to scroll months
                    showYearDropdown // Allows users to scroll years
                    dropdownMode="select" // Allows users to scroll through years and months via dropdown
                    minDate={minSelectableDate} // Disable past dates and unselectable dates
                    filterDate={date => !isDateDisabled(date)} // Disable specific dates
                  />
                </div>
                <br /><br />
                <p className='basket-welcome'>Discount code?</p>
                <input type='text' className='basket-input' onChange={(e) => setDiscountCode(e.target.value)} placeholder='Discount code' />
                <br /><br /><br />
                <hr />
                <br /><br />
                <div className='basket-total'>
                  <p>Total ({basketItems.length} items)</p>
                  <p>£{basketItems.reduce((accumulator, basketItem) => { return accumulator + (basketItem.grams === 0 ? (basketItem.quantity * basketItem.product.priceInCents / 100) : basketItem.quantity * ((basketItem.product.pricePerKgInCents / 100000) * basketItem.grams).toFixed(2) ); }, 0).toFixed(2)}</p>
                </div>
                <br /><br />
                <hr />
                <br /><br />
                <button className='button' onClick={() => {handleCheckout()}}>Checkout</button>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Basket;
