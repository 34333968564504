import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import NotFound from '../../NotFound';
import '../../../styles/admin/Forms.css';

function View() {
  const { user } = useAuth();

  const [userData, setUserData] = useState();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [contactRequests, setContactRequests] = useState([]);
  const [loadingContactRequests, setLoadingContactRequests] = useState(true);
  const [deleteContactRequestId, setDeleteContactRequestId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    setLoadingContactRequests(true);
    const fetchContactRequests = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/contact-requests', {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setContactRequests(response.data.contactRequests);
        setLoadingContactRequests(false);
      } catch (error) {
        console.error('Error fetching discount codes:', error);
        setLoadingContactRequests(false);
      }
    };

    fetchContactRequests();
  }, []);

  const handleDeleteConfirmation = (contactRequestId) => {
    setDeleteContactRequestId(contactRequestId);
    setIsPopupOpen(true);
  };

  const confirmDelete = async () => {
    if (deleteContactRequestId) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/contact-request/${deleteContactRequestId}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });

        // Update discount code state correctly
        setContactRequests(currentContactRequests =>
          currentContactRequests.filter(contactRequest => contactRequest._id !== deleteContactRequestId)
        );

        setFeedbackMessage("Contact request deleted successfully!"); // Set success message
      } catch (error) {
        console.error('Error deleting discount code:', error);
        setFeedbackMessage("Failed to delete discount code. Please try again."); // Set error message
      } finally {
        setIsPopupOpen(false); // Close the popup
      }
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/\//g, '/');
  };

  const formatTime = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleTimeString('en-GB', options);
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <h1 style={{ textAlign: 'center' }}>Messages</h1>
        <br />
        <p style={{ textAlign: 'center' }}>All messages received</p>
        <br /><br />
        <table className='table'>
          <thead>
            <tr>
              <th>Email</th>
              <th>Phone</th>
              <th>Name</th>
              <th>Subject</th>
              <th>Message</th>
              <th>Date</th>
              <th>Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {contactRequests.map(contactRequest => (
              <tr key={contactRequest._id}>
                <td>{contactRequest.email}</td>
                <td>{contactRequest.phone}</td>
                <td>{contactRequest.name}</td>
                <td>{contactRequest.subject}</td>
                <td>{contactRequest.message}</td>
                <td>{formatDate(contactRequest.createdAt)}</td>
                <td>{formatTime(contactRequest.createdAt)}</td>
                <td><button className='button' onClick={() => handleDeleteConfirmation(contactRequest._id)}>Delete</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Footer />

      <FeedbackPopup
        isOpen={isPopupOpen}
        message={"Are you sure you want to delete this contact request?"}
        onClose={closePopup}
        onConfirm={confirmDelete}
        confirmButtonText="Confirm Delition"
        isAdmin={userData && userData.type === 'admin'} // Pass isAdmin prop based on user role
      />
    </>
  );
}

export default View;