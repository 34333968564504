import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import NotFound from '../../NotFound';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import '../../../styles/admin/Forms.css';

function View() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);

  const [stores, setStores] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    setLoadingOrders(true);
      const fetchOrders = async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_API_URL + `/orders`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
          });
          setOrders(response.data.orders);
          setLoadingOrders(false);
        } catch (error) {
          console.error('Error fetching orders:', error);
          setLoadingOrders(false);
        }
      };
      fetchOrders();
  }, []);

  const handleUpdate = async (orderId, updatedStatus) => {
    const formData = new FormData();
    formData.append('status', updatedStatus);

    try {
      await axios.put(process.env.REACT_APP_API_URL + `/order/status/${orderId}`, formData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json'
        }
      }).then(() => {
        setFeedbackMessage(`Order ${updatedStatus}`);
        setIsPopupOpen(true);
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoadingOrders(true);
      const fetchProducts = async () => {
        try {
          const response = await axios.get(process.env.REACT_APP_API_URL + `/orders`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
          });
          setOrders(response.data.orders);
          setLoadingOrders(false);
        } catch (error) {
          console.error('Error fetching orders:', error);
          setLoadingOrders(false);
        }
      };
  
      fetchProducts();
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const isToday = (date) => {
    const today = new Date();
    console.log(new Date(date).toDateString(), today.toDateString())
    return new Date(date).toDateString() === today.toDateString();
  };

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <h1 style={{ textAlign: 'center' }}>All orders</h1>
        <br />
        <p style={{ textAlign: 'center' }}>All orders ever placed</p>
        <br /><br />
        <div className='table-container'>
          <table className='table'>
            <thead>
              <tr>
                <th>Order No.</th>
                <th>User</th>
                <th>Items</th>
                <th>Store</th>
                <th>Pick-up</th>
                <th>Action</th>
                <th>Action</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orders
                .filter(order => order.status != "pending")
                .sort((a, b) => new Date(a.selectedDate) - new Date(b.selectedDate))
                .map(order => (
                  <tr key={order._id} style={order.status === 'completed' ? { backgroundColor: '#4CAF50'} : order.status === 'cancelled' ? { backgroundColor: '#FF6347' } : { backgroundColor: '#FFA500' } }>
                    <td style={{ fontSize: '20px' }}><b>#{order.orderNumber}</b></td>
                    <td>{order.user.email}</td>
                    <td>
                      {order.basketItems.map(item => (
                        <>
                          <br />
                          <p key={item._id}><b>{item.quantity}</b> | {item.product.name} | {item.size != 'N/A' ? 'Size: ' + item.size : item.product.pricePerKgInCents === 0 ? 'Whole' : 'Sliced ' + item.grams + 'g'}</p>
                          <br />
                        </>
                      ))}
                    </td>
                    <td>{order.store.name}</td>
                    <td>{isToday(order.selectedDate) ? <b>TODAY</b> : formatDate(order.selectedDate)}</td>
                    <td>
                      <button className='button' onClick={() => navigate(`/admin/order/edit/${order._id}`)}>Edit</button>
                    </td>
                    {order.status === 'confirmed' ? (
                      <td><button className='button' onClick={() => handleUpdate(order._id, 'completed')}>Complete</button></td>
                    ) : (
                      <td><button className='button' onClick={() => handleUpdate(order._id, 'confirmed')} style={{ backgroundColor: 'grey' }}>Undo complete</button></td>
                    )}
                    <td>
                      <button className='button' onClick={() => navigate(`/admin/order/refund/${order._id}`)}>Refund</button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      <Footer />

      {/* Render the FeedbackPopup */}
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
        showCancel={false} // Only show "Close" button
      />
    </>
  );
}

export default View;