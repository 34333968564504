import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { db, auth } from '../config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, setDoc, doc } from 'firebase/firestore';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import FeedbackPopup from '../components/widgets/FeedbackPopUp';
import '../styles/Signup.css';

function Signup() {

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreePolicy, setAgreePolicy] = useState(false);

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const usersCollectionRef = collection(db, "users");

  const signup = async () => {
    try {
      // Check if name and surname are entered
      if (!name.trim() || !surname.trim()) {
        setFeedbackMessage('Name and surname are required.');
        setIsPopupOpen(true);
        return;
      }

      // Check if policy is agreed
      if (!agreePolicy) {
        setFeedbackMessage('You must agree to the privacy policy');
        setIsPopupOpen(true);
        return;
      }

      let firebaseUserId;

      // Create user in Firebase Auth and Firestore
      const cred = await createUserWithEmailAndPassword(auth, email, password);
      firebaseUserId = cred.user.uid;
      await setDoc(doc(usersCollectionRef, cred.user.uid), {
        email: email,
        registrationDate: new Date()
      });

      // Create user in MongoDB
      await axios.post(`${process.env.REACT_APP_API_URL}/user/create`, {
        firebaseUserId: firebaseUserId,
        email: email,
        name: name,
        surname: surname,
      }, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY
        }
      });

      navigate('/');
    } catch (err) {
      // Handle Firebase and Axios errors
      if (err.code === 'auth/invalid-email') {
        setFeedbackMessage('Invalid email.');
      } else if (err.code === 'auth/missing-password') {
        setFeedbackMessage('Missing password.');
      } else if (err.code === 'auth/weak-password') {
        setFeedbackMessage('Weak password, should be at least 6 characters.');
      } else if (err.code === 'auth/email-already-in-use') {
        setFeedbackMessage('Email already in use.');
      } else {
        console.error('Error:', err.message);
        setFeedbackMessage('Error signing up: ' + err.message);
      }
      setIsPopupOpen(true);
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage("");
  };

  return (
    <div>
      <Navbar />
      <div className='global-container'>
        <div className='signup-container'>
          <h2 className='signup-title'>Sign Up</h2>
          <div className='signup-form'>
            <input 
              type='text' 
              name='name' 
              value={name} 
              onChange={(e) => setName(e.target.value)}
              className='signup-input' 
              placeholder='Name' 
              required 
            />
            <input 
              type='text' 
              name='surname' 
              value={surname} 
              onChange={(e) => setSurname(e.target.value)}
              className='signup-input' 
              placeholder='Surname' 
              required 
            />
            <input 
              type='email' 
              name='email' 
              value={email} 
              onChange={(e) => setEmail(e.target.value)}
              className='signup-input' 
              placeholder='Email' 
              required 
            />
            <input 
              type='password' 
              name='password' 
              value={password} 
              onChange={(e) => setPassword(e.target.value)}
              className='signup-input' 
              placeholder='Password' 
              required 
            />
            <label className='signup-privacy-policy'>
              <input 
                type='checkbox' 
                name='agreePolicy' 
                checked={agreePolicy} 
                onChange={(e) => setAgreePolicy(e.target.checked)}
                required 
              />
              I agree to the <Link to='/privacy-policy'>Privacy Policy</Link>
            </label>
            <button className='signup-button' onClick={signup}>Sign Up</button>
          </div>
          <div className='signup-login-link'>
            <p>Already have an account? <Link to='/login'>Log in</Link></p>
          </div>
        </div>
      </div>
      <Footer />
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
      />
    </div>
  );
}

export default Signup;
