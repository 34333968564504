import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import NotFound from '../../NotFound';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import '../../../styles/admin/Forms.css';

function Edit() {
  const { user } = useAuth();
  const { categoryId } = useParams();

  const [userData, setUserData] = useState();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState();

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + `/category/${categoryId}`, // Adjust endpoint based on your API structure
          {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
          }
        );
        const categoryData = response.data.category;
        setName(categoryData.name);
        setDescription(categoryData.description);
        setImages(categoryData.images); 
      } catch (error) {
        console.error('Error fetching category:', error);
      }
    };

    fetchCategoryData();
  }, [categoryId]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i]);
      }
    }

    try {
      await axios.put(process.env.REACT_APP_API_URL + `/category/${categoryId}`, formData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
          'Content-Type': 'multipart/form-data',
        }
      });
      setFeedbackMessage("Category updated successfully!"); // Set success message
    } catch (error) {
      console.error('Error submitting form:', error);
      setFeedbackMessage("Failed to update category. Please try again."); // Set error message
    } finally {
      setIsPopupOpen(true); // Open the popup regardless of success or failure
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <div className='form-container'>
          <h1>Edit Category</h1>
          <br /><br />
          <form onSubmit={handleSubmit}>
            <p>Name</p>
            <br />
            <input 
              type='text' 
              className='form-input' 
              placeholder='Name' 
              value={name}
              onChange={(e) => setName(e.target.value)} 
            />
            <br /><br />
            <p>Description</p>
            <br />
            <textarea 
              className='form-input' 
              placeholder='Description' 
              value={description}
              onChange={(e) => setDescription(e.target.value)} 
            />
            <br /><br />
            <p>Images (Square)</p>
            <br />
            <input 
              type='file' 
              className='form-input' 
              onChange={(e) => setImages(e.target.files)} 
              multiple 
            />
            <br /><br />
            <button type='submit' className='button'>Update</button>
          </form>
        </div>
      </div>

      <Footer />

      {/* Render the FeedbackPopup */}
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
        showCancel={false} // Only show "Close" button
      />
    </>
  );
}

export default Edit;
