import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import NotFound from '../../NotFound';
import '../../../styles/admin/Forms.css';

function Edit() {
  const { user } = useAuth();
  const { productId } = useParams();

  const [loadedCategories, setLoadedCategories] = useState([]);
  const [userData, setUserData] = useState();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [weightInGrams, setWeightInGrams] = useState(0);
  const [priceInCents, setPriceInCents] = useState(0);
  const [pricePerKgInCents, setPricePerKgInCents] = useState(0);
  const [images, setImages] = useState();
  const [availableSizes, setAvailableSizes] = useState([]);
  const [available, setAvailable] = useState(true);
  const [visible, setVisible] = useState(true);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/categories', {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setLoadedCategories(response.data.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/product/${productId}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        const product = response.data.product;
        setName(product.name);
        setDescription(product.description);
        setCategories(product.categories);
        setWeightInGrams(product.weightInGrams);
        setPriceInCents(product.priceInCents);
        setPricePerKgInCents(product.pricePerKgInCents);
        setAvailableSizes(product.availableSizes || []);
        setAvailable(product.available);
        setVisible(product.visible);
        setImages(product.images);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProductData();
  }, [productId]);

  const handleCategoriesChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
    setCategories(selectedOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    categories.forEach((category, index) => {
      formData.append(`categories[${index}]`, category);
    });
    formData.append('weightInGrams', weightInGrams);
    formData.append('priceInCents', priceInCents);
    formData.append('pricePerKgInCents', pricePerKgInCents);
    formData.append('available', available);
    formData.append('visible', visible);
    
    // Check if no sizes are selected
    if (availableSizes.length === 0) {
      formData.append('availableSizes', 'sold out');
    } else {
      // Append each available size separately
      availableSizes.forEach((size, index) => {
        formData.append(`availableSizes[${index}]`, size);
      });
    }
    
    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append('images', images[i]);
      }
    }

    try {
      await axios.put(process.env.REACT_APP_API_URL + `/product/${productId}`, formData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
          'Content-Type': 'multipart/form-data',
        }
      });
      setFeedbackMessage("Product updated successfully!"); // Set success message
    } catch (error) {
      console.error('Error submitting form:', error);
      setFeedbackMessage("Failed to update product. Please try again."); // Set error message
    } finally {
      setIsPopupOpen(true); // Open the popup regardless of success or failure
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <div className='form-container'>
          <h1>Edit Product</h1>
          <br /><br />
          <form onSubmit={handleSubmit}>
            <p>Visible</p>
            <br />
            <select 
              className='form-input' 
              value={visible} 
              onChange={(e) => setVisible(e.target.value)} 
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
            <br />
            <p>Availability</p>
            <br />
            <select 
              className='form-input' 
              value={available} 
              onChange={(e) => setAvailable(e.target.value)} 
            >
              <option value={true}>Available</option>
              <option value={false}>Unavailable</option>
            </select>
            <br />
            <p>Name</p>
            <br />
            <input 
              type='text' 
              className='form-input' 
              value={name}
              placeholder='Name' 
              onChange={(e) => setName(e.target.value)} 
            />
            <br /><br />
            <p>Description</p>
            <br />
            <textarea 
              className='form-input' 
              value={description}
              placeholder='Description' 
              onChange={(e) => setDescription(e.target.value)} 
            />
            <br /><br />
            <p>Categories</p>
            <br />
            <select 
              className='form-input' 
              multiple 
              value={categories}
              onChange={handleCategoriesChange} 
            >
              {loadedCategories.map(category => (
                <option key={category.id} value={category._id}>{category.name}</option>
              ))}
            </select>
            <br /><br />
            {/* Only show weight and price fields if the selected category does not include 'sliced meats and cheeses' */}
            {categories.includes('671122fe0b49bc81594988a0') ? (
              <>
                <p>Available Sizes</p>
                <br />
                {['XS', 'S', 'M', 'L', 'XL'].map(size => (
                  <label key={size}>
                    <input 
                      type='checkbox' 
                      value={size}
                      checked={availableSizes.includes(size)}
                      onChange={(e) => setAvailableSizes(prev =>
                        e.target.checked ? [...prev, size] : prev.filter(s => s !== size)
                      )}
                    /> {size}
                  </label>
                ))}
                <br /><br />
                <p>Weight (g)</p>
                <br />
                <input 
                  type='number' 
                  className='form-input' 
                  value={weightInGrams}
                  placeholder='Weight (g)' 
                  onChange={(e) => setWeightInGrams(e.target.value)} 
                />
                <br /><br />
                <p>Price (cents)</p>
                <br />
                <input 
                  type='number' 
                  className='form-input' 
                  value={priceInCents}
                  placeholder='Price (cents)' 
                  onChange={(e) => setPriceInCents(e.target.value)} 
                />
                <br /><br />
              </>
            ) : (
              categories.length === 0 ? null : (
                !categories.includes('66b122598ef12d3980d89389') ? (
                  <>
                    <p>Weight (g)</p>
                    <br />
                    <input 
                      type='number' 
                      className='form-input' 
                      value={weightInGrams}
                      placeholder='Weight (g)' 
                      onChange={(e) => setWeightInGrams(e.target.value)} 
                    />
                    <br /><br />
                    <p>Price (cents)</p>
                    <br />
                    <input 
                      type='number' 
                      className='form-input' 
                      value={priceInCents}
                      placeholder='Price (cents)' 
                      onChange={(e) => setPriceInCents(e.target.value)} 
                    />
                    <br /><br />
                  </>
                ) : (
                  <>
                    <p>Price per KG (cents)</p>
                    <br />
                    <input 
                      type='number' 
                      className='form-input' 
                      value={pricePerKgInCents}
                      placeholder='Price per KG (cents)' 
                      onChange={(e) => setPricePerKgInCents(e.target.value)} 
                    />
                    <br /><br />
                  </>
                )
              )
            )}
            <p>Images (Square)</p>
            <br />
            <input 
              type='file' 
              className='form-input' 
              onChange={(e) => setImages(e.target.files)} 
              multiple 
            />
            <br /><br />
            <button type='submit' className='button'>Update</button>
          </form>
        </div>
      </div>

      <Footer /> 

      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
      />
    </>
  );
}

export default Edit;