import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader'; // Import a spinner
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import ProductCard from '../components/ProductCard';
import '../styles/Category.css';

function Category() {
  const { categorySlug } = useParams();

  const [category, setCategory] = useState(null);
  const [loadingCategory, setLoadingCategory] = useState(true);

  const [products, setProducts] = useState(null);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    setLoadingCategory(true);
    const fetchCategory = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/category/slug/${categorySlug}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setCategory(response.data.category);
        setLoadingCategory(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setLoadingCategory(false); // Ensure loading state is updated on error
      }
    };

    fetchCategory();
  }, [categorySlug]);

  useEffect(() => {
    setLoadingProducts(true);
    if(!loadingCategory) {
      const fetchCategoryProducts = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/product/category/${category._id}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
          });
          setProducts(response.data.products.filter(product => product.visible));
          setLoadingProducts(false);
        } catch (error) {
          console.error('Error fetching products:', error);
          setLoadingProducts(false); // Ensure loading state is updated on error
        }
      };

      fetchCategoryProducts();
    }
  }, [category]);

  // Function to split products into chunks of 4
  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <>
      <Navbar />
      <div className="global-container">
        {!loadingProducts && !loadingCategory ? (
          <>
            <h1>{category.name}</h1>
            <br />
            <p>{category.description}</p>
            <br />
            {chunkArray(products, 4).map((productRow, rowIndex) => (
              <div className='row' key={rowIndex}>
                {productRow.map((product, index) => (
                  <div className='category-product-column' key={index}>
                    <ProductCard 
                      id={product._id} 
                      name={product.name} 
                      img={`${process.env.REACT_APP_API_URL}/${product.images[0]}`} 
                      weightInGrams={product.weightInGrams} 
                      pricePerKgInCents={product.pricePerKgInCents} 
                      availableSizes={product.availableSizes}
                      available={product.available}
                      description={`£${product.pricePerKgInCents === 0 ? product.priceInCents / 100 : product.pricePerKgInCents / 1000 + ' / 100g '}`} 
                      slug={product.slug}
                    />
                  </div>
                ))}
              </div>
            ))}
          </>
        ) : (
          <div className="loading-container">
            <ClipLoader size={50} color="#007bff" loading={true} />
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default Category;
