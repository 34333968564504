import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import NotFound from '../../NotFound';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import '../../../styles/admin/Forms.css';

function DiscountCode() {
  const { user } = useAuth();

  const [userData, setUserData] = useState();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [code, setCode] = useState("");
  const [discountType, setDiscountType] = useState(""); // To store the type of discount (percentage or fixed)
  const [percentage, setPercentage] = useState("");
  const [fixed, setFixed] = useState("");
  const [minimumSpend, setMinimumSpend] = useState();
  const [maxUsePerUser, setMaxUsePerUser] = useState();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('code', code);
    formData.append('minimumSpend', minimumSpend);
    formData.append('active', true);

    if (discountType === 'percentage') {
      formData.append('percentage', percentage);
    } else if (discountType === 'fixed') {
      formData.append('fixed', fixed);
    }

    try {
      await axios.post(process.env.REACT_APP_API_URL + '/discount-code/new', formData, {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json'
        }
      });
      setFeedbackMessage("Discount Code created successfully!"); // Set success message
    } catch (error) {
      console.error('Error submitting form:', error);
      setFeedbackMessage("Failed to create discount code. Please try again."); // Set error message
    } finally {
      setIsPopupOpen(true); // Open the popup regardless of success or failure
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <div className='form-container'>
          <h1>New Discount Code</h1>
          <br /><br />
          <form onSubmit={handleSubmit}>
            <p>Code</p>
            <br />
            <input 
              type='text' 
              className='form-input' 
              placeholder='Code' 
              onChange={(e) => setCode(e.target.value)} 
            />
            <br /><br />

            <p>Discount Type</p>
            <br />
            <select 
              className='form-input' 
              onChange={(e) => setDiscountType(e.target.value)} 
              value={discountType}
            >
              <option value="">Select Type</option>
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed Amount</option>
            </select>
            <br /><br />

            {/* Conditionally render the input based on discount type */}
            {discountType === 'percentage' && (
              <>
                <p>Percentage</p>
                <br />
                <input 
                  type='number' 
                  className='form-input' 
                  placeholder='Percentage' 
                  onChange={(e) => setPercentage(e.target.value)} 
                />
                <br /><br />
              </>
            )}

            {discountType === 'fixed' && (
              <>
                <p>Fixed Amount</p>
                <br />
                <input 
                  type='number' 
                  className='form-input' 
                  placeholder='Fixed Amount' 
                  onChange={(e) => setFixed(e.target.value)} 
                />
                <br /><br />
              </>
            )}

            <p>Minimum Spend</p>
            <br />
            <input 
              type='number' 
              className='form-input' 
              placeholder='Minimum Spend' 
              onChange={(e) => setMinimumSpend(e.target.value)} 
            />
            <br /><br />

            <p>Max Use Per User</p>
            <br />
            <input 
              type='number' 
              className='form-input' 
              placeholder='Max Use Per User' 
              onChange={(e) => setMaxUsePerUser(e.target.value)} 
            />
            <br /><br />

            <button type='submit' className='button'>Create</button>
          </form>
        </div>
      </div>

      <Footer />

      {/* Render the FeedbackPopup */}
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
        showCancel={false} // Only show "Close" button
      />
    </>
  );
}

export default DiscountCode;
