import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import NotFound from '../../NotFound';
import '../../../styles/admin/Forms.css';

function View() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [userData, setUserData] = useState();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [deleteProductId, setDeleteProductId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    setLoadingProducts(true);
    const fetchProducts = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + '/products', {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setProducts(response.data.products);
        setLoadingProducts(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoadingProducts(false);
      }
    };

    fetchProducts();
  }, []);

  const handleDeleteConfirmation = (productId) => {
    setDeleteProductId(productId); // Set the product ID to delete
    setIsPopupOpen(true); // Open the confirmation popup
  };

  const confirmDelete = async () => {
    if (deleteProductId) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/product/${deleteProductId}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });

        // Update products state correctly
        setProducts(currentProducts =>
          currentProducts.filter(product => product._id !== deleteProductId)
        );

        setFeedbackMessage("Product deleted successfully!"); // Set success message
      } catch (error) {
        console.error('Error deleting product:', error);
        setFeedbackMessage("Failed to delete product. Please try again."); // Set error message
      } finally {
        setIsPopupOpen(false); // Close the popup
      }
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <h1 style={{ textAlign: 'center' }}>All Products</h1>
        <br />
        <p style={{ textAlign: 'center' }}>View and Edit Products</p>
        <br /><br />
        <table className='table'>
          <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Categories</th>
              <th>Weight</th>
              <th>Price</th>
              <th>Price Per Kg</th>
              <th>Visible</th>
              <th>Available</th>
              <th>Edit</th>
              {/* <th>Delete</th> */}
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product._id}>
                <td><Link to={`/product/${product.slug}`}><img src={`${process.env.REACT_APP_API_URL}/${product.images[0]}`} alt={product.name} /></Link></td>
                <td>{product.name}</td>
                <td>
                  {product.categories.map((category, index) => (
                    <>
                      <span key={index}>
                        {category.name}
                        {index < product.categories.length - 1 && ", "}
                      </span>
                      <br />
                    </>
                  ))}
                </td>
                <td>{product.weightInGrams != 0 ? product.weightInGrams + 'g' : 'N/A'}</td>
                <td>{product.priceInCents != 0 ? '£' + (product.priceInCents / 100) : 'N/A'}</td>
                <td>{product.pricePerKgInCents != 0 ? '£' + (product.pricePerKgInCents / 100) : 'N/A'}</td>
                <td>{product.visible ? 'Visible' : <p><i>Hidden</i></p>}</td>
                <td>{product.available ? 'Available' : <p><i>Unavailable</i></p>}</td>
                <td><Link to={`/admin/product/edit/${product._id}`}><button className='button'>Edit</button></Link></td>
                {/* <td><button className='button' onClick={() => handleDeleteConfirmation(product._id)}>Delete</button></td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Footer />

      <FeedbackPopup
        isOpen={isPopupOpen}
        message={"Are you sure you want to delete this product?"}
        onClose={closePopup}
        onConfirm={confirmDelete}
        confirmButtonText="Confirm Deletion"
        isAdmin={userData && userData.type === 'admin'} // Pass isAdmin prop based on user role
      />
    </>
  );
}

export default View;
