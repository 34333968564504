import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// Import custom styles
import '../../styles/components/widgets/CategoriesSlider.css';

// Import required modules
import { FreeMode, Pagination } from 'swiper/modules';

export default function App({ categories }) {
  const navigate = useNavigate();

  const handleClick = (category) => {
    navigate(`/shop/${category.slug}`);
  };

  return (
    <Swiper
      breakpoints={{
        320: { slidesPerView: 3, spaceBetween: 20 },
        640: { slidesPerView: 2.5, spaceBetween: 30 },
        1024: { slidesPerView: 6.5, spaceBetween: 30 },
      }}
      freeMode={true}
      pagination={{ clickable: true }}
      modules={[FreeMode, Pagination]}
    >
      {categories.map((category) => (
        <SwiperSlide
          key={category._id}
          className="category-slide"
          onClick={() => handleClick(category)}
        >
          <div className="image-container">
            <img
              src={`${process.env.REACT_APP_API_URL}/${category.images[0]}`}
              alt={category.name}
            />
          </div>
          <h4 className="category-name">{category.name}</h4>
          <br /><br />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
