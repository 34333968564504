import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../components/AuthContext';
import Navbar from '../../../components/common/Navbar';
import Footer from '../../../components/common/Footer';
import FeedbackPopup from '../../../components/widgets/FeedbackPopUp';
import NotFound from '../../NotFound';
import '../../../styles/admin/Forms.css';

function View() {
  const { user } = useAuth();

  const [userData, setUserData] = useState();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [discountCodes, setDiscountCodes] = useState([]);
  const [loadingDiscountCodes, setLoadingDiscountCodes] = useState(true);
  const [changeDiscountCodeId, setChangeDiscountCodeId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_API_URL + `/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setUserData(response.data.user);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    fetchDiscountCodes(); // Initial fetch
  }, []);
  
  const fetchDiscountCodes = async () => {
    setLoadingDiscountCodes(true);
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/discount-codes', {
        headers: {
          'X-API-KEY': process.env.REACT_APP_API_KEY,
        },
      });
      setDiscountCodes(response.data.discountCodes);
    } catch (error) {
      console.error('Error fetching discount codes:', error);
    } finally {
      setLoadingDiscountCodes(false);
    }
  };

  const handleDiscountCodeConfirmation = (discountCodeId) => {
    setChangeDiscountCodeId(discountCodeId); // Set the discount code ID to delete
    setIsPopupOpen(true); // Open the confirmation popup
  };

  const confirmChange = async () => {
    if (changeDiscountCodeId) {
      try {
        await axios.put(`${process.env.REACT_APP_API_URL}/discount-code/change-status/${changeDiscountCodeId}`, {}, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });

        fetchDiscountCodes(); // Re-fetch to get updated data
        setFeedbackMessage("Discount code status changed successfully!"); // Set success message
      } catch (error) {
        console.error('Error deleting discount code:', error);
        setFeedbackMessage("Failed to delete discount code. Please try again."); // Set error message
      } finally {
        setIsPopupOpen(false); // Close the popup
      }
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  if (!userData || userData.type !== 'admin') {
    return <NotFound />
  }

  return (
    <>
      <Navbar />
      <div className='global-container'>
        <h1 style={{ textAlign: 'center' }}>Discount Codes</h1>
        <br />
        <p style={{ textAlign: 'center' }}>View Activate/Deactivate Discount Codes</p>
        <br /><br />
        <table className='table'>
          <thead>
            <tr>
              <th>Code</th>
              <th>Percentage</th>
              <th>Fixed</th>
              <th>Minimum Spend</th>
              <th>Max Use Per User</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {discountCodes.map(discountCode => (
              <tr key={discountCode._id}>
                <td>{discountCode.code}</td>
                <td>{discountCode.percentage ? + discountCode.percentage + '%' : 'N/A'}</td>
                <td>{discountCode.fixed ? '£' + discountCode.fixed : 'N/A'}</td>
                <td>£{discountCode.minimumSpend}</td>
                <td>{discountCode.maxUsePerUser}</td>
                {discountCode.active ? (
                  <td><button className='button' style={{ backgroundColor: 'gray' }} onClick={() => handleDiscountCodeConfirmation(discountCode._id)}>Deactivate</button></td>
                ) : (
                  <td><button className='button' onClick={() => handleDiscountCodeConfirmation(discountCode._id)}>Activate</button></td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Footer />

      <FeedbackPopup
        isOpen={isPopupOpen}
        message={"Are you sure you want to activate/deactivate this discount code?"}
        onClose={closePopup}
        onConfirm={confirmChange}
        confirmButtonText="Confirm Activation/Deactivation"
        isAdmin={userData && userData.type === 'admin'} // Pass isAdmin prop based on user role
      />
    </>
  );
}

export default View;
